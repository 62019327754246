<div class="container" [ngClass]="{ locked: toVerify }" (click)="isRedirect ? redirectToOpenLink() : null">
  <div class="app-container" [ngClass]="{ isActive: isSelected }">
    <img [src]="imageLink" [alt]="customIcon ? customIcon : key" />
    <div class="content">
      <span class="name" *ngIf="isDirectory" geauiTooltipOnEllipsisV2 pTooltip="{{ 'APPS.' + key + '.NAME' | translate }}">{{
        'APPS.' + key + '.NAME' | translate
      }}</span>
      <span
        class="name"
        *ngIf="!isDirectory"
        geauiTooltipOnEllipsisV2
        pTooltip="{{ 'APPS.' + key + '.WIDGETS.APP_LINK.NAME' | translate }}"
        >{{ 'APPS.' + key + '.WIDGETS.APP_LINK.NAME' | translate }}</span
      >
      <span class="description" geauiTooltipOnEllipsisV2 pTooltip="{{ description }}">{{ description }}</span>
    </div>
  </div>
  <div class="lock-container" *ngIf="toVerify">
    <geaui-icon-v2 [file]="'16px_lock'" color="white" />
  </div>
</div>
