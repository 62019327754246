import { Component, Input } from '@angular/core';

@Component({
  selector: 'gea-id-workspace-app-tile',
  templateUrl: './app-tile.component.html',
  styleUrls: ['./app-tile.component.scss'],
})
export class AppTileComponent {
  @Input() key = '';
  @Input() description = '';
  @Input() customIcon: string | undefined;
  @Input() toVerify = false;
  @Input() isSelected = false;
  @Input() imageLink: string | undefined;
  @Input() openLink: string | undefined;
  @Input() isDirectory = false;
  @Input() isRedirect = true;

  redirectToOpenLink() {
    if (!this.toVerify && this.openLink) {
      location.href = this.openLink;
    }
  }
}
