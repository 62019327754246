import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ColumnDefinition,
  ComplexDialogV2Service,
  ErrorHandlerV2Service,
  MembershipState,
  Membership,
  PermissionsState,
  PermissionKey,
  SnackbarService,
  TableServiceV2,
} from '@gea/digital-ui-lib';
import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';
import { MEMBERSHIP_EDIT_ACTION, membershipsColumnDefinitions } from '../../models/memberships-column-definitions.config';
import { map } from 'rxjs/operators';
import { PortalMembershipDialogComponent } from './membership-dialog/portal-membership-dialog.component';
import { MembershipService, OrgaData, RoleWithOrgaType, UserDetailService } from '@gea-id/shared';
import { Store } from '@ngxs/store';
import { CultureService } from '@gea-id/shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gea-id-memberships-list-page',
  styleUrl: './portal-memberships-list-page.component.scss',
  templateUrl: './portal-memberships-list-page.component.html',
})
export class PortalMembershipsListPageComponent implements OnInit {
  public userId = '';
  public membershipTableData: Membership[] = [];
  public columnDefinitions: ColumnDefinition[] = membershipsColumnDefinitions;
  public totalRecords = 0;

  public loading = true;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private membershipService: MembershipService,
    private tableService: TableServiceV2,
    private complexDialogService: ComplexDialogV2Service,
    private errorHandlerService: ErrorHandlerV2Service,
    private snackBarService: SnackbarService,
    private cultureService: CultureService,
    private store: Store,
    private destroyRef: DestroyRef,
    protected userDetailService: UserDetailService
  ) {}

  ngOnInit(): void {
    this.userId = this.userDetailService.currentUserId;
    this.loading = !this.userDetailService.isMembershipsLoaded();

    this.userDetailService.memberships$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((membershipListResponse) => (this.loading = membershipListResponse === null)),
        filter((membershipListResponse) => !!membershipListResponse),
        tap((membershipListResponse) => (this.totalRecords = membershipListResponse?.entryCount ?? 0)),
        map((membershipListResponse) => membershipListResponse?.pageEntries ?? []),
        map((memberships) => memberships.map((membership) => this.mapInheritedMembershipState(membership)))
      )
      .subscribe((memberships) => {
        if (!memberships) return;
        this.membershipTableData = memberships;
      });

    this.tableService.actions
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(
          (action) => action.tableId === this.userDetailService.tableIdMemberships && action.action === MEMBERSHIP_EDIT_ACTION
        ),
        map(({ rowData }) => rowData as Membership)
      )
      .subscribe((rowData) => this.openEditMemberShipDialog(rowData));

    this.hasNoUpdateOrganisationPermission()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.removeEditRow());

    this.cultureService.loadOrganisations();
    this.cultureService.loadMemberships();
    this.cultureService.loadRoles();
  }

  openAddMemberShipDialog() {
    this.complexDialogService.open(
      {
        title: 'X.LABEL.ADD',
        yes: 'X.BUTTON.SAVE',
        no: 'X.BUTTON.CANCEL',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        confirmCallback: (data: unknown) => this.addMembership(data as { organisation: OrgaData; role: RoleWithOrgaType }),
      },
      PortalMembershipDialogComponent
    );
  }

  openEditMemberShipDialog = (membershipRowData: Membership) => {
    if (membershipRowData.inherited) return;

    this.complexDialogService.open(
      {
        title: 'X.LABEL.EDIT',
        yes: 'X.BUTTON.SAVE',
        no: 'X.BUTTON.CANCEL',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        confirmCallback: (data: unknown) =>
          this.editMembership(data as { membershipID?: string; organisation: OrgaData; role: RoleWithOrgaType }),
        data: membershipRowData,
      },
      PortalMembershipDialogComponent
    );
  };

  get hasNoCreateUserPermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      map((permissions) => {
        return !permissions.includes(PermissionKey.CREATE_USER_INVITATION);
      })
    );
  }

  private hasNoUpdateOrganisationPermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      map((permissions) => !permissions.includes(PermissionKey.UPDATE_ORGANIZATION)),
      filter((hasNoUpdateOrganisationPermission) => hasNoUpdateOrganisationPermission)
    );
  }

  private addMembership(data: { organisation: OrgaData; role: RoleWithOrgaType }) {
    const membership = this.createMembershipFromOrgaAndRoleData(data);
    this.membershipTableData = [...this.membershipTableData, membership];
    this.membershipService
      .createMembership(membership.userId ?? '', {
        roleId: membership.roleId,
        organizationId: membership.organizationId,
      })
      .subscribe({
        next: (createdMembership) => {
          membership.id = createdMembership.id;
          this.handleSuccessfulMembershipCreation();
        },
        error: (error: ApiErrorResponse) => this.errorHandlerService.handleError(error),
      });
  }

  private editMembership(data: { organisation: OrgaData; role: RoleWithOrgaType }) {
    const updateMembershipData = this.createMembershipFromOrgaAndRoleData(data);
    const index = this.membershipTableData.findIndex((membership) => membership.id === updateMembershipData.id);
    if (index === -1) {
      return;
    }
    const membership = this.membershipTableData[index];
    const isEqualMembership =
      membership.organizationName === updateMembershipData.organizationName &&
      membership.roleName === updateMembershipData.roleName;

    if (isEqualMembership) return;

    this.membershipTableData[index] = {
      ...membership,
      roleId: updateMembershipData.roleId,
      roleName: updateMembershipData.roleName,
      organizationName: updateMembershipData.organizationName,
      organizationId: updateMembershipData.organizationId,
    };
    this.membershipService
      .updateMembership(updateMembershipData.userId ?? '', this.membershipTableData[index].id, this.membershipTableData[index])
      .subscribe({
        next: () => this.handleSuccessfulMembershipUpdate(),
        error: (error: ApiErrorResponse) => this.errorHandlerService.handleError(error),
      });
  }

  private handleSuccessfulMembershipCreation() {
    this.userDetailService.memberships = this.membershipTableData;
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
      severity: 'success',
    });
  }

  private handleSuccessfulMembershipUpdate() {
    this.userDetailService.memberships = this.membershipTableData;
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
      severity: 'success',
    });
  }

  private createMembershipFromOrgaAndRoleData({
    organisation,
    role,
    membershipID,
  }: {
    organisation: OrgaData;
    role: RoleWithOrgaType;
    membershipID?: string;
  }): Membership {
    return {
      id: membershipID ?? '',
      organizationId: organisation.orgaId ?? '',
      roleId: role.id ?? '',
      userId: this.userId,
      roleName: (role.name ?? '').toUpperCase(),
      organizationName: organisation.name ?? '',
      customerNumber: organisation.customerNumber ?? '',
      state: MembershipState.PENDING,
      inherited: false,
    };
  }

  private mapInheritedMembershipState(membership: Membership): Membership {
    if (membership.inherited) {
      return {
        ...membership,
        state: MembershipState.INHERITED,
      };
    }
    return membership;
  }

  private removeEditRow() {
    this.columnDefinitions = this.columnDefinitions.filter((column) => {
      return column.key !== 'edit';
    });
  }
}
