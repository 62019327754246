import { NgModule } from '@angular/core';
import { PortalUsersListPageComponent } from './user-list/portal-users-list-page.component';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PortalUserDetailComponent } from './user-list/user-detail/portal-user-detail.component';
import { PortalMembershipsListPageComponent } from './user-list/user-detail/memberships-list/portal-memberships-list-page.component';
import { PermissionKey } from '@gea/digital-ui-lib';
import { checkPermissionsGuard, DiscardChangesGuard } from '@gea-id/shared';
import { PortalEditUserFormComponent } from './user-list/user-detail/edit-user-form/portal-edit-user-form.component';
import { PortalUserInviteComponent } from './user-list/user-invite/portal-user-invite.component';
import { ProfileComponent } from './profile/profile.component';
import { PersonalDataComponent } from './profile/personal-data/personal-data.component';
import { MembershipsComponent } from './profile/memberships/memberships.component';
import { OrganizationsListPageComponent } from './organizations/organizations-list-page/organizations-list-page.component';
import { OrganizationDetailComponent } from './organizations/organization-detail/organization-detail.component';
import { OrganizationInfoComponent } from './organizations/organization-detail/organization-info/organization-info.component';
import { orgaIdGuards, orgaRoutes } from './organizations/models/organizations.routing';
import { OrganizationBusinessRelationsComponent } from './organizations/organization-detail/organization-business-relations/organization-business-relations.component';
import { OrganizationSitesComponent } from './organizations/organization-detail/organization-business-sites/organization-sites.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

const routes: Routes = [
  {
    path: 'administration',
    children: [
      {
        path: 'user',
        component: PortalUsersListPageComponent,
        canActivate: [MsalGuard, checkPermissionsGuard([PermissionKey.READ_USER_LIST])],
      },
      {
        path: 'user/user-invite',
        component: PortalUserInviteComponent,
        canActivate: [MsalGuard],
        canDeactivate: [DiscardChangesGuard],
      },
      {
        path: 'user/:userId',
        component: PortalUserDetailComponent,
        canActivate: [MsalGuard],
        canDeactivate: [DiscardChangesGuard],
        children: [
          {
            path: 'edit-user',
            component: PortalEditUserFormComponent,
            canActivate: [MsalGuard, checkPermissionsGuard([PermissionKey.CREATE_USER_INVITATION, PermissionKey.UPDATE_USER])],
          },
          {
            path: 'memberships',
            component: PortalMembershipsListPageComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [MsalGuard, checkPermissionsGuard([PermissionKey.READ_OWN_PROFILE])],
        children: [
          {
            path: '',
            redirectTo: 'personal-data',
            pathMatch: 'full',
          },
          {
            path: 'personal-data',
            component: PersonalDataComponent,
            canDeactivate: [DiscardChangesGuard],
            canActivate: [MsalGuard],
          },
          {
            path: 'memberships',
            component: MembershipsComponent,
            canActivate: [MsalGuard],
          },
        ],
      },
      {
        path: 'organization',
        component: OrganizationsListPageComponent,
        canActivate: [MsalGuard, checkPermissionsGuard([PermissionKey.READ_ORGANIZATION_LIST])],
      },
      {
        path: 'organization/:orgaId',
        component: OrganizationDetailComponent,
        canDeactivate: [DiscardChangesGuard],
        canActivate: [MsalGuard, orgaIdGuards.isValid],
        children: [
          {
            path: orgaRoutes.BASIC_INFO,
            component: OrganizationInfoComponent,
            canActivate: [MsalGuard],
          },
          {
            path: orgaRoutes.BUSINESS_RELATIONS,
            component: OrganizationBusinessRelationsComponent,
            canActivate: [MsalGuard, orgaIdGuards.isNotNew],
          },
          {
            path: orgaRoutes.SITES,
            component: OrganizationSitesComponent,
            canActivate: [MsalGuard, orgaIdGuards.isNotNew],
          },
        ],
      },
      {
        path: 'notification-settings',
        component: NotificationSettingsComponent,
        canDeactivate: [DiscardChangesGuard],
        canActivate: [MsalGuard, checkPermissionsGuard([PermissionKey.NOTIFICATION_SETTINGS_ACCESS])],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PortalAdministrationRoutingModule {}
