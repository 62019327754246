import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { OrganizationDetailService } from './services/organization-detail.service';
import { ComponentCanDeactivate } from '@gea-id/shared';
import { FormService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrl: './organization-detail.component.scss',
})
export class OrganizationDetailComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  @Input({ required: true }) orgaId = '';

  tabItems: Observable<MenuItem[]> = this.organizationDetailService.tabMenu$;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private organizationDetailService: OrganizationDetailService,
    private formService: FormService
  ) {}

  ngOnInit() {
    this.organizationDetailService.init(this.orgaId);
    this.organizationDetailService.update({ orgaId: this.orgaId });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  changeTab($event?: MenuItem) {
    if ($event) void this.router.navigate(['organization', this.orgaId, $event.routerLink]);
  }

  routeOrgaList() {
    void this.router.navigate(['administration', 'organization']);
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.formService.areFormChanges;
  }
}
