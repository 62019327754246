import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  COMPLEX_DIALOG_INPUT_DATA,
  ComplexDialogEmbeddedView,
  ComplexDialogV2Service,
  DialogV2Service,
  SelectOption,
} from '@gea/digital-ui-lib';
import { distinctUntilChanged, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrganizationDetailService } from '../../../services/organization-detail.service';
import { finalize } from 'rxjs/operators';
import { BusinessRelationResponseV1, RelationsApiService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-workspace-business-relations-edit-modal',
  templateUrl: './business-relations-edit-modal.component.html',
  styleUrl: './business-relations-edit-modal.component.scss',
})
export class BusinessRelationsEditModalComponent implements OnInit, ComplexDialogEmbeddedView {
  loading = true;
  form = this.fb.group({
    relationType: ['', [Validators.required]],
    relatedOrgaId: ['', [Validators.required]],
  });

  relationTypeOptions: SelectOption<string>[] = [];
  possibleTargets: SelectOption<string>[] = [];

  public emptyMessageKey?: string;

  get isAcceptDisabled() {
    return this.form.invalid || this.loading;
  }

  protected get isEditing(): boolean {
    return !!this.data.relation;
  }

  constructor(
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
    private organizationDetailsService: OrganizationDetailService,
    private complexDialogService: ComplexDialogV2Service,
    private confirmDialog: DialogV2Service,
    private relationsApiService: RelationsApiService,
    @Inject(COMPLEX_DIALOG_INPUT_DATA)
    private data: {
      orgaId: string;
      relation?: BusinessRelationResponseV1;
    }
  ) {}

  ngOnInit() {
    // Init RelationTypeOptions
    this.organizationDetailsService.relationTypes$
      .pipe(
        tap((relationTypes) => {
          this.relationTypeOptions = relationTypes.map(
            (relationType) =>
              ({
                nameKey: 'X.ROLE.' + relationType.toUpperCase(),
                value: relationType,
              }) as SelectOption<string>
          );
        }),
        switchMap((relationTypes) =>
          this.form.controls.relationType.valueChanges.pipe(startWith(relationTypes.find((x) => x === this.data.relation?.name)))
        ),

        distinctUntilChanged(),
        tap(() => (this.loading = false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((selectedType) => {
        this.form.patchValue({ relationType: selectedType }, { emitEvent: false });
        if (this.isEditing && selectedType === this.data?.relation?.name) {
          this.form.patchValue({ relatedOrgaId: this.data?.relation?.relatedOrganizationId });
          this.fetchOrgas(this.data?.relation?.relatedName ?? '', []).subscribe();
        } else {
          this.form.patchValue({ relatedOrgaId: null });
        }
      });
  }

  public fetchOrgas = (query: string, options: SelectOption<string>[]) => {
    if (!this.form.value.relationType) return of([]);

    if (query.length < 2) {
      this.emptyMessageKey = 'X.AUTOCOMPLETE-SELECT.EMPTY_MESSAGES.MIN_LENGTH';
      if (!query.length && options.length) {
        return of(options);
      }
      return of([]);
    }

    return this.relationsApiService
      .relationTypesGetRelatableOrganizations({
        nameContains: query ?? '',
        typeName: this.form.value.relationType,
        page: '0',
        pageSize: '20',
      })
      .pipe(
        map((response) =>
          response.map(
            (relation) =>
              ({
                name: relation.name,
                value: relation.id,
              }) as SelectOption<string>
          )
        ),
        tap((relations) => {
          this.emptyMessageKey = undefined;
          this.possibleTargets = relations;
        })
      );
  };

  onAcceptClick() {
    const relationType = this.form.value.relationType;
    if (!relationType) {
      throw new Error('relationType is not set');
    }

    this.loading = true;
    const request = {
      orgaId: this.data.orgaId,
      relationId: this.data.relation?.id,
      businessRelationUpdateCreateRequest: {
        name: relationType,
        relatedOrganizationId: this.form.value.relatedOrgaId,
      },
    };
    this.complexDialogService.emitDataOutputForComponent(request);
  }

  protected deleteRelation() {
    this.complexDialogService.close();
    this.confirmDialog.open({
      title: 'ORGANIZATION.DETAIL.RELATIONS.DELETE.MODAL.TITLE',
      message: 'ORGANIZATION.DETAIL.RELATIONS.DELETE.MODAL.MESSAGE',
      yes: 'X.BUTTON.DELETE',
      no: 'X.BUTTON.CANCEL',
      buttonTypeYes: 'cancel-red',
      confirmCallback: () => {
        if (!this.data?.relation) {
          // eslint-disable-next-line no-console
          console.error('No relation to delete');
          return;
        }
        return this.removeRelation(this.data.relation);
      },
    });
  }

  private removeRelation(relation: BusinessRelationResponseV1): Observable<void> {
    return this.organizationDetailsService
      .deleteRelation({
        orgaId: this.data.orgaId,
        relationId: relation.id,
      })
      .pipe(finalize(() => this.confirmDialog.close()));
  }
}
