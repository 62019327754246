import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  COMPLEX_DIALOG_INPUT_DATA,
  ComplexDialogEmbeddedView,
  ComplexDialogV2Service,
  DialogV2Service,
  SiteResponse,
} from '@gea/digital-ui-lib';
import { OrganizationDetailService } from '../../../services/organization-detail.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'gea-id-workspace-organization-site-edit-modal',
  templateUrl: './organization-site-edit-modal.component.html',
  styleUrl: './organization-site-edit-modal.component.scss',
})
export class OrganizationSiteEditModalComponent implements ComplexDialogEmbeddedView, OnInit {
  loading = true;
  form = this.fb.group({
    siteName: ['', [Validators.required]],
    street: ['', [Validators.required]],
    city: ['', [Validators.required]],
    zipCode: ['', Validators.required],
    country: ['DE', Validators.required],
    phoneNumber: [''],
  });

  protected countries$ = this.organizationService.countriesOptions$;

  get isAcceptDisabled() {
    return this.form.invalid || this.loading;
  }

  get isEditing(): boolean {
    return !!this.data.site;
  }

  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationDetailService,
    private complexDialogService: ComplexDialogV2Service,
    private confirmDialog: DialogV2Service,
    @Inject(COMPLEX_DIALOG_INPUT_DATA)
    private data: { orgaId: string; site?: SiteResponse }
  ) {}

  ngOnInit() {
    this.loading = false;

    if (this.isEditing) {
      this.form.patchValue({
        siteName: this.data.site?.name,
        phoneNumber: this.data.site?.phoneNumber,
        city: this.data.site?.address?.city,
        street: this.data.site?.address?.street,
        zipCode: this.data.site?.address?.zipCode,
        country: this.data.site?.address?.country,
      });
    }
  }

  onAcceptClick() {
    this.complexDialogService.emitDataOutputForComponent({
      id: this.data.site?.id,
      name: this.form.value.siteName,
      address: {
        city: this.form.value.city,
        country: this.form.value.country,
        street: this.form.value.street,
        zipCode: this.form.value.zipCode,
      },
      phoneNumber: this.form.value.phoneNumber ?? '',
    } as SiteResponse);
  }

  deleteSite() {
    this.complexDialogService.close();
    this.confirmDialog.open({
      title: 'ORGANIZATION.DETAIL.RELATIONS.DELETE.MODAL.TITLE',
      message: 'ORGANIZATION.DETAIL.RELATIONS.DELETE.MODAL.MESSAGE',
      yes: 'X.BUTTON.DELETE',
      no: 'X.BUTTON.CONFIRM',
      buttonTypeYes: 'cancel-red',
      confirmCallback: () => {
        return this.removeSite(this.data.site);
      },
    });
  }

  private removeSite(site?: SiteResponse): Observable<void> {
    if (!site) {
      throw new Error('No site provided');
    }

    return this.organizationService.deleteSite(this.data.orgaId, site.id).pipe(finalize(() => this.confirmDialog.close()));
  }
}
