import { ActivatedRouteSnapshot } from '@angular/router';

export const orgaRoutes = {
  BASIC_INFO: 'info',
  USERS: 'users',
  HISTORY: 'history',
  BUSINESS_RELATIONS: 'relations',
  SITES: 'sites',
};

export const NEW_ORGA_ID = 'new';

export const orgaIdGuards = {
  isNotNew: (route: ActivatedRouteSnapshot) => route.paramMap.get('orgaId')?.trim() !== NEW_ORGA_ID,
  isValid: (route: ActivatedRouteSnapshot) => {
    const orgaId = route.paramMap.get('orgaId')?.trim();
    if (!orgaId) {
      return false;
    }
    return orgaId === NEW_ORGA_ID || /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/.test(orgaId);
  },
};
