import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonV2Module } from '@gea/digital-ui-lib';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../environments/models/environment.model';
import { LegalDocumentsService } from '../services/legal-documents.service';
import { Router } from '@angular/router';

@Component({
  selector: 'gea-id-workspace-logged-out',
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonV2Module],
  templateUrl: './logged-out.component.html',
  styleUrl: './logged-out.component.scss',
})
export class LoggedOutComponent {
  currentLanguage = '';

  constructor(
    private router: Router,
    public legalDocumentsService: LegalDocumentsService,
    @Inject('redirectUrl') private redirectUrl: string,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration
  ) {}

  login() {
    void this.router.navigate(['/dashboard']);
  }
}
