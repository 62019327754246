<div class="logged-out-container">
  <img class="background-circles" src="/assets/images/background-circles.svg" alt="Background circles" />
  <div class="geaui-grid max-height">
    <div
      class="session-expired-container geaui-col-desktop-xl-start-10 geaui-col-desktop-md-start-4 geaui-col-desktop-md-6 geaui-col-tablet-lg-start-2 geaui-col-tablet-lg-10 geaui-col-tablet-sm-start-2 geaui-col-tablet-sm-10">
      <div class="header">
        <img src="/assets/images/GEA_Logo_w_Claim_sRGB_Solid_neg.svg" alt="GEA Logo with claims" />
      </div>
      <div class="session-expired-content">
        <img class="pb-48" src="/assets/images/70px_time-clock.svg" alt="Icon of a clock" />
        <div class="gea-font-heading-32 pb-24">{{ 'SESSION.EXPIRED.TITLE' | translate }}</div>
        <div class="gea-font-body-18 pb-48">{{ 'SESSION.EXPIRED.DESCRIPTION' | translate }}</div>
        <geaui-button-v2 [postIcon]="true" (click)="login()">{{ 'SESSION.EXPIRED.LOGIN-BUTTON' | translate }}</geaui-button-v2>
      </div>
    </div>
    <div class="footer geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12">
      <span class="copyright">© GEA Group Services GmbH 2024</span>
      <span (click)="legalDocumentsService.openImprint()">{{ 'UI-LIB.FOOTER.IMPRINT' | translate }}</span>
      <span (click)="legalDocumentsService.openTermsAndConditions()">{{ 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS' | translate }}</span>
      <span (click)="legalDocumentsService.openDataprivacy()">{{ 'UI-LIB.FOOTER.DATAPRIVACY' | translate }}</span>
      <span (click)="legalDocumentsService.openCookieSettings()">{{ 'UI-LIB.FOOTER.COOKIES' | translate }}</span>
    </div>
  </div>
</div>
