import { Component, Input } from '@angular/core';

@Component({
  selector: 'gea-id-workspace-default-widget',
  templateUrl: './default-widget.component.html',
  styleUrls: ['./default-widget.component.scss'],
})
export class DefaultWidgetComponent {
  @Input() editModeActive!: boolean;
  @Input() widgetId!: string;
  // callback for opening add-widget dialog on button trigger
  @Input() widgetCallback?: () => void;

  addWidget() {
    if (this.widgetCallback) {
      this.widgetCallback();
    }
  }
}
