import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import {
  ApiErrorResponse,
  App,
  WidgetType,
  ErrorHandlerV2Service,
  GoogleAnalyticsService,
  GridService,
  LayoutService,
  PermissionKey,
  StepsService,
  PermissionsState,
  ComplexDialogEmbeddedView,
  DialogV2Service,
  SnackbarService,
} from '@gea/digital-ui-lib';
import { Observable, take } from 'rxjs';

import { AppAndIconService } from '../services/app-and-icon.service';
import { Store } from '@ngxs/store';
import { OrganizationService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-workspace-add-widget',
  templateUrl: './add-widget.component.html',
  styleUrls: ['./add-widget.component.scss'],
})
export class AddWidgetComponent implements ComplexDialogEmbeddedView, OnInit {
  loading = true;
  cardTitles = ['', ''];
  oneCardTitle = [''];
  appSelected = false;
  currentIndex = 0;
  hasVerificationPermission = false;
  selectedApp?: App;
  selectedWidget?: WidgetType;
  appList?: App[];
  verifyAppList?: App[];
  showSaveButton = false;
  requestVerificationCallback: () => Observable<void>;

  constructor(
    private gridService: GridService,
    private translate: TranslateService,
    private appService: AppAndIconService,
    private dialog: DialogV2Service,
    private errorHandlerService: ErrorHandlerV2Service,
    public layout: LayoutService,
    private ga: GoogleAnalyticsService,
    private snackbarService: SnackbarService,
    private stepService: StepsService,
    public orgaService: OrganizationService,
    private store: Store
  ) {
    this.requestVerificationCallback = this.requestVerification.bind(this);
    // regular ngx translate syntax
    /* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment */
    this.translate
      .get('DASHBOARD.CHOOSE-APP')
      .pipe(take(1))
      .subscribe((result) => {
        this.cardTitles[0] = result;
        this.oneCardTitle[0] = result;
      });
    this.translate
      .get('DASHBOARD.CHOOSE-WIDGET')
      .pipe(take(1))
      .subscribe((result) => (this.cardTitles[1] = result));

    this.appService
      .getAppsAndInitIcons()
      .pipe(take(1))
      .subscribe(({ appList, verifyAppList }) => {
        this.loading = false;
        this.appList = appList;
        this.verifyAppList = verifyAppList;
      });
    /* eslint-enable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment */
  }

  ngOnInit(): void {
    // This is the official syntax of ngxs
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.store.select(PermissionsState.userPermissions).subscribe((permissions) => {
      this.hasVerificationPermission = permissions.includes(PermissionKey.REQUEST_VERIFICATION);
    });
  }

  onAcceptClick() {
    if (this.selectedWidget) {
      this.gridService.widgetAdded.next(this.selectedWidget.name);
      this.ga.addWidgetPush(this.selectedWidget.name);
    }
  }

  onIndexChanged(newIndex: number) {
    if (newIndex === 1) {
      this.selectedWidget = undefined;
    }
    this.currentIndex = newIndex;
  }

  selectApp(selectedApp: App) {
    this.appSelected = true;
    this.selectedApp = selectedApp;
    this.showSaveButton = true;
    this.reinitializeSteps();
  }

  selectWidget(selectedApp: App, selected = false, nextStep = 0) {
    this.appSelected = selected;
    this.selectedWidget = selectedApp.widgetTypes[0];
    this.selectedApp = selectedApp;
    this.showSaveButton = true;
    this.reinitializeSteps(nextStep);
  }

  onStartVerification() {
    if (this.hasVerificationPermission) {
      this.dialog.open({
        closable: false,
        title: 'DASHBOARD.VERIFY.START-TITLE',
        message: 'DASHBOARD.VERIFY.START-MESSAGE',
        yes: 'X.BUTTON.CONFIRM',
        no: 'X.BUTTON.CANCEL',
        confirmCallback: this.requestVerificationCallback,
      });
    }
  }

  requestVerification(): Observable<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const observable: Observable<void> = this.orgaService.requestOrganizationVerification();
    // handle error and success outside of ConfirmPrompt to avoid circular dependency issues
    observable.subscribe({
      next: () => {
        this.snackbarService.add({
          summary: '',
          detail: 'DASHBOARD.VERIFY.SUCCESS',
          severity: 'success',
        });
      },
      error: (e: ApiErrorResponse) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.errorHandlerService.handleError(e);
      },
    });
    return observable;
  }

  private reinitializeSteps(nextStep = 0) {
    if (this.appSelected) {
      this.stepService.initializeSteps(this.cardTitles.length, nextStep);
    } else {
      this.stepService.initializeSteps(this.oneCardTitle.length, nextStep);
    }
  }
}
