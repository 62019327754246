import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first, Observable, of, switchMap, tap } from 'rxjs';
import { UserInviteService } from '@gea-id/shared';
import { DialogV2Service, SnackbarService } from '@gea/digital-ui-lib';
import { FormService } from '@gea-id/shared';
import { ComponentCanDeactivate } from '@gea-id/shared';
import { CultureService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-workspace-user-invite',
  templateUrl: './portal-user-invite.component.html',
  styleUrl: './portal-user-invite.component.scss',
})
export class PortalUserInviteComponent implements OnInit, ComponentCanDeactivate {
  private readonly USER_LIST_PATH = 'administration/user';
  isLoading = false;

  constructor(
    private router: Router,
    private userInviteService: UserInviteService,
    private formCacheService: FormService,
    private snackBarService: SnackbarService,
    private cultureService: CultureService,
    private dialogService: DialogV2Service
  ) {}

  ngOnInit(): void {
    this.cultureService.loadResourcesData();
    this.cultureService.loadOrganisations();
    this.cultureService.loadRoles();
  }

  canDeactivate() {
    return !this.formCacheService.areFormChanges;
  }

  pageHeaderBackClick() {
    void this.router.navigate([this.USER_LIST_PATH]);
  }

  onSave(): Observable<boolean> {
    return of({}).pipe(
      switchMap(() => this.userInviteService.createUser()),
      switchMap(() => of(true)),
      tap(() => this.handleSuccessSave()),
      first()
    );
  }

  isInvitable(): Observable<boolean> {
    return of({}).pipe(
      tap(() => (this.isLoading = true)),
      switchMap(() => this.userInviteService.isInvitable()),
      tap((isInvitable: boolean) => {
        this.isLoading = false;
        if (!isInvitable) this.buildIsNotInvitableDialog();
      }),
      first()
    );
  }

  buildIsNotInvitableDialog() {
    this.dialogService.open({
      title: 'USERS.USER_INVITE.IS_NOT_INVITABLE_DIALOG.TITLE',
      message: 'USERS.USER_INVITE.IS_NOT_INVITABLE_DIALOG.MESSAGE',
      hideButtons: true,
    });
  }

  private handleSuccessSave() {
    this.formCacheService.notifyFormChanges(false);
    void this.router.navigate([this.USER_LIST_PATH]);
    this.snackBarService.add({
      summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
      severity: 'success',
    });
  }
}
