<geaui-page-layout [headerTemplate]="header" [contentTemplate]="content" [sidebarTemplate]="sidebar">
  <ng-template #header>
      @if (noAuth !== undefined && !isOnLoggedOutPage && !isOnHelpGuidePage) {
          <geaui-header
                  [noAuth]="noAuth"
                  [googleAnalyticsActivated]="true"
                  [showBanner]="false"
                  headerTitle="Portal">
          </geaui-header>
      }
      @else {
          <geaui-offline-header
                  headerTitle="Portal">
          </geaui-offline-header>
      }
  </ng-template>

  <ng-template #content>
    <div
      class="spinner"
      *ngIf="(!tokenReady || !userReady) && !loggedOutPageReady && !isOnHelpGuidePage">
      <p-progressSpinner animationDuration="2s" strokeWidth="4"></p-progressSpinner>
    </div>
    <div class="content-body" [ngClass]="{ 'logged-out-page': isOnLoggedOutPage }">
      @if (isOnAdminSite) {
        <gea-id-workspace-portal-administration></gea-id-workspace-portal-administration>
      } @else if (isOnLoggedOutPage || isOnHelpGuidePage) {
        <router-outlet></router-outlet>
      } @else {
        <gea-id-workspace-home></gea-id-workspace-home>
      }
    </div>
  </ng-template>
  <ng-template #sidebar>
    @if (!noAuth && tokenReady) {
      <geaui-sidebar-v2
        [navigationItems]="navItems"
        [slimView]="true"
        [bottomNavigation]="botItems"
        [devisionIconPath]="environment.appContentStorageURL + 'core/portal/icons/24px_portal.svg'"
        devisionIconBackgroundColor="var(--geaui-night-blue)"
        devisionName="GEA Portal">
      </geaui-sidebar-v2>
    }
  </ng-template>
</geaui-page-layout>
<gea-id-introduction-guide></gea-id-introduction-guide>
