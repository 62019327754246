import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntroductionGuideService {
  private SHOW_INTRODUCTION_GUIDE_KEY = 'showIntroductionGuide';
  protected _showIntroductionGuide$ = new BehaviorSubject<boolean>(false);

  get showIntroductionGuide$() {
    return this._showIntroductionGuide$.asObservable();
  }

  public openByFirstLogin(): void {
    if (localStorage.getItem(this.SHOW_INTRODUCTION_GUIDE_KEY) !== 'false') {
      this.open();
    }
  }

  public open(): void {
    this._showIntroductionGuide$.next(true);
  }

  public close(): void {
    this._showIntroductionGuide$.next(false);
    localStorage.setItem(this.SHOW_INTRODUCTION_GUIDE_KEY, 'false');
  }
}
