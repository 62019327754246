import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { filter } from 'rxjs';
import { ResourcesSelectOptionsModel, SelectOption, WizardStep, WizardV2Service } from '@gea/digital-ui-lib';
import { CustomerUserTypeDto, DashboardInfo, UserInviteService } from '@gea-id/shared';
import { FormService } from '@gea-id/shared';
import { GeaEmailValidator } from '@gea-id/shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CultureService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-workspace-user-invite-form',
  templateUrl: './portal-user-invite-form.component.html',
  styleUrl: './portal-user-invite-form.component.scss',
})
export class PortalUserInviteFormComponent implements OnInit {
  @Input() isLoading = false;

  protected readonly FORM_CONTROL_FIRSTNAME = 'firstName';
  protected readonly FORM_CONTROL_LASTNAME = 'lastName';
  protected readonly FORM_CONTROL_EMAIL = 'email';
  protected readonly FORM_CONTROL_LANGUAGE = 'language';
  protected readonly FORM_CONTROL_CUSTOMER_TYPE = 'customerUserType';
  protected readonly customerTypeOptions: SelectOption<string>[] = [CustomerUserTypeDto.BASIC, CustomerUserTypeDto.SHARED].map(
    (key) => ({
      value: key,
      nameKey: `X.CUSTOMER_USER_TYPE.${key}`,
    })
  );

  private readonly nameRegex = /^([^0-9]*)$/;

  form = this.formBuilder.group({
    [this.FORM_CONTROL_FIRSTNAME]: ['', [Validators.required, Validators.maxLength(64), Validators.pattern(this.nameRegex)]],
    [this.FORM_CONTROL_LASTNAME]: ['', [Validators.required, Validators.maxLength(64), Validators.pattern(this.nameRegex)]],
    [this.FORM_CONTROL_EMAIL]: [
      null,
      {
        validators: [Validators.required, Validators.maxLength(80), GeaEmailValidator.email()],
        updateOn: 'blur',
      },
    ],
    [this.FORM_CONTROL_LANGUAGE]: ['', []],
    [this.FORM_CONTROL_CUSTOMER_TYPE]: [CustomerUserTypeDto.BASIC, [Validators.required]],
  });

  protected languageOptions: SelectOption<string>[] = [];
  protected dashboardOptions: SelectOption<string>[] = [];

  private wizardStep?: WizardStep;

  constructor(
    private formBuilder: FormBuilder,
    private wizardService: WizardV2Service,
    private userInviteService: UserInviteService,
    private formCacheService: FormService,
    private cultureService: CultureService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.cultureService.resourceData$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((resourcesModel) => {
      this.fillFormLanguageData(resourcesModel);
    });

    this.wizardService.currentStep$
      .pipe(
        filter((step) => step.stepIndex === 1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((step) => {
        this.wizardStep = step;
      });

    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.evaluateWizardStep();
      this.evaluateUserUpdate();
      this.formCacheService.notifyFormChanges(true);
    });
    this.cultureService.dashboardInfos$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((dashboardInfos) => {
      this.fillDashboardInfoData(dashboardInfos);
    });
  }

  private fillFormLanguageData(resourcesModel: ResourcesSelectOptionsModel | null) {
    if (resourcesModel) {
      this.languageOptions =
        resourcesModel.languageOptions?.map((option) => ({
          ...option,
          nameKey: 'X.LANGUAGE.' + option.value.toUpperCase(),
        })) ?? [];
    }
  }

  private fillDashboardInfoData(dashboardInfos: DashboardInfo[] | null) {
    if (dashboardInfos) {
      this.dashboardOptions = this.mapToSelectOption(dashboardInfos);
    }
  }

  private evaluateWizardStep() {
    if (!this.wizardStep) return;
    const isValid = this.form.valid;
    this.wizardService.updateWizardStep({
      ...this.wizardStep,
      nextButtonDisabled: !isValid,
      isCompleted: isValid,
    });
  }

  private evaluateUserUpdate() {
    if (this.form.valid) {
      const firstName = this.form.value.firstName ?? '';
      const lastName = this.form.value.lastName ?? '';
      const customerUserType = this.form.value.customerUserType ?? CustomerUserTypeDto.BASIC;
      const email = this.form.value.email ?? '';
      const language = this.form.value.language ?? '';
      const userData = { firstName, lastName, email, language, customerUserType: customerUserType };
      this.userInviteService.userData = { ...userData };
    }
  }

  private mapToSelectOption(dashboardInfos: DashboardInfo[]): SelectOption<string>[] {
    return dashboardInfos.map(
      (dashboardInfo): SelectOption<string> => ({
        name: dashboardInfo.name,
        value: dashboardInfo.id,
      })
    );
  }
}
