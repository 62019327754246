import { Component, OnInit } from '@angular/core';
import { IntroductionGuideView } from './introduction-guide.model';
import { IntroductionGuideService } from './introduction-guide.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ContactFormService } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-id-introduction-guide',
  templateUrl: './introduction-guide.component.html',
  styleUrls: ['./introduction-guide.component.scss'],
})
export class IntroductionGuideComponent implements OnInit {
  public views: IntroductionGuideView[] = [
    {
      text: 'INTRODUCTION-GUIDE.PORTAL-DASHBOARD-CONTENT',
      image: './assets/images/introduction-guide/PortalDashboard.png',
      showAction: false,
    },
    {
      text: 'INTRODUCTION-GUIDE.NOTIFICATION-SETTINGS-CONTENT',
      image: './assets/images/introduction-guide/NotificationSettings.png',
      showAction: true,
      actionText: 'INTRODUCTION-GUIDE.NOTIFICATION-SETTINGS-CONTENT.ACTION',
      onClickAction: this.onHandleGoToNotificationSettings.bind(this),
    },
    {
      text: 'INTRODUCTION-GUIDE.CONTACT-GEA-CONTENT',
      image: './assets/images/introduction-guide/ContactGEA.png',
      showAction: true,
      actionText: 'INTRODUCTION-GUIDE.CONTACT-GEA-CONTENT.ACTION',
      onClickAction: this.onHandleGoToContactGea.bind(this),
    },
  ];
  public currentIndex = 0;
  public show$!: Observable<boolean>;
  public sanitizedSafeHtmlSubject = new BehaviorSubject<SafeHtml>('');

  private readonly PORTAL_ADMINISTRATION_PATH = 'administration';
  private readonly NOTIFICATION_SETTINGS_PATH = 'notification-settings';

  constructor(
    private introductionGuideService: IntroductionGuideService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private sidebarService: ContactFormService
  ) {}

  ngOnInit(): void {
    this.show$ = this.introductionGuideService.showIntroductionGuide$;
    this.updateTranslatedHtmlContent();
  }

  public get sanitizedSafeHtml$(): Observable<SafeHtml> {
    return this.sanitizedSafeHtmlSubject.asObservable();
  }

  public get currentView(): IntroductionGuideView {
    return this.views[this.currentIndex];
  }

  public onHandleCloseModal() {
    this.introductionGuideService.close();
  }

  public onHandleChangeCurrentIndex(index: number): void {
    this.currentIndex = index;
    this.updateTranslatedHtmlContent();
  }

  private updateTranslatedHtmlContent() {
    const currentText = this.views[this.currentIndex].text;
    this.translateService.get(currentText).subscribe((currentTranslatedText) => {
      this.sanitizedSafeHtmlSubject.next(this.domSanitizer.bypassSecurityTrustHtml((currentTranslatedText as string) ?? ''));
    });
  }

  private onHandleGoToNotificationSettings() {
    this.introductionGuideService.close();
    void this.router.navigate([this.PORTAL_ADMINISTRATION_PATH, this.NOTIFICATION_SETTINGS_PATH]);
  }

  private onHandleGoToContactGea() {
    this.introductionGuideService.close();
    this.sidebarService.showContactForm();
  }
}
