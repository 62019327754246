import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  TableServiceV2,
  ListResponse,
  DialogV2Service,
  SnackbarService,
  ErrorHandlerV2Service,
  ApiErrorResponse,
  Membership,
  UserState,
} from '@gea/digital-ui-lib';
import { Store } from '@ngxs/store';
import { filter, first, map, switchMap, tap } from 'rxjs';
import { MembershipColumns } from './model/memberships-columns.model';
import { MembershipService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-workspace-memberships',
  templateUrl: './memberships.component.html',
  styleUrl: './memberships.component.scss',
})
export class MembershipsComponent implements OnInit {
  totalRecords = 0;
  loading = true;
  userId?: string;
  memberships: Membership[] = [];
  columnDefinitions = MembershipColumns;

  protected readonly TABLE_ID = 'user-profile-memberships-table';

  constructor(
    private membershipService: MembershipService,
    private store: Store,
    private tableService: TableServiceV2,
    private dialogService: DialogV2Service,
    private snackbarService: SnackbarService,
    private errorHandlerService: ErrorHandlerV2Service,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.store
      .select(UserState.user)
      .pipe(
        map((user) => user.userId ?? ''),
        filter((userId) => !!userId),
        tap((userId) => (this.userId = userId)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.fetchMemberships());

    this.tableService.actions
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((action) => action.tableId === this.TABLE_ID)
      )
      .subscribe((action) => {
        if (action.action === 'delete-membership') {
          this.dialogService.open({
            title: 'MEMBERSHIPS.DIALOG.DELETE.TITLE',
            message: 'MEMBERSHIPS.DIALOG.DELETE.MESSAGE',
            yes: 'X.BUTTON.REMOVE',
            no: 'X.BUTTON.CANCEL',
            buttonTypeYes: 'cancel-red',
            confirmCallback: () => this.removeMembership(action.rowData as string),
          });
        } else if (action.action === 'accept-membership') {
          this.dialogService.open({
            title: 'MEMBERSHIPS.DIALOG.ACCEPT.TITLE',
            message: 'MEMBERSHIPS.DIALOG.ACCEPT.MESSAGE',
            yes: 'X.BUTTON.ACCEPT',
            no: 'X.BUTTON.CANCEL',
            confirmCallback: () => this.acceptMembership(action.rowData as string),
          });
        } else if (action.action === 'decline-membership') {
          this.dialogService.open({
            title: 'MEMBERSHIPS.DIALOG.DECLINE.TITLE',
            message: 'MEMBERSHIPS.DIALOG.DECLINE.MESSAGE',
            yes: 'X.BUTTON.DECLINE',
            no: 'X.BUTTON.CANCEL',
            confirmCallback: () => this.declineMembership(action.rowData as string),
          });
        }
      });
  }

  removeMembership(id: string) {
    this.membershipService
      .deleteMembership(this.userId ?? '', id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.snackbarService.add({
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
            severity: 'success',
          });
          this.fetchMemberships();
        },
        error: (error: ApiErrorResponse) => this.errorHandlerService.handleError(error),
      });
  }

  acceptMembership(id: string) {
    this.membershipService
      .acceptMembership(this.userId || '', id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.snackbarService.add({
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
            severity: 'success',
          });
          this.fetchMemberships();
        },
        error: (error: ApiErrorResponse) => this.errorHandlerService.handleError(error),
      });
  }

  declineMembership(id: string) {
    this.membershipService
      .declineMembership(this.userId || '', id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.snackbarService.add({
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            detail: 'MEMBERSHIP.DECLINE.SUCCESS.DETAIL',
            severity: 'success',
          });
          this.fetchMemberships();
        },
        error: (error: ApiErrorResponse) => this.errorHandlerService.handleError(error),
      });
  }

  private fetchMemberships() {
    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(
        tap(() => (this.loading = true)),
        switchMap((filterSettings) => this.membershipService.getMembershipsPaginated(this.userId ?? '', filterSettings)),
        filter((membershipListResponse) => !!membershipListResponse),
        tap((membershipResponse) => (this.totalRecords = membershipResponse.entryCount)),
        map((membershipListResponse) => this.mapRoleNamesToUpperCase(membershipListResponse))
      )
      .subscribe({
        next: (membershipListResponse) => {
          this.memberships = membershipListResponse.pageEntries;
          this.loading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandlerService.handleError(error);
          this.loading = false;
        },
      });
  }

  private mapRoleNamesToUpperCase(response: ListResponse<Membership[]>): ListResponse<Membership[]> {
    return {
      entryCount: response.entryCount,
      pageEntries: response.pageEntries.map((membership) => ({
        ...membership,
        roleName: membership.roleName?.toUpperCase(),
      })),
    };
  }
}
