<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-header>
    <geaui-page-header
      [withSidebar]="true"
      [pageTitle]="'APP-DETAIL.TITLE.LABEL' | translate"
      (backArrowClicked)="routeAllApps()">
    </geaui-page-header>
  </ng-container>
  <ng-container page-content>
    <div class="app-detail-container">
      <div class="geaui-grid geaui-sidebar-grid">
        @if (loading) {
          <p-skeleton
            class="geaui-col-desktop-xl-start-7 geaui-col-phone-sm-3 geaui-col-phone-lg-3 geaui-col-tablet-sm-2 geaui-col-tablet-lg-2 geaui-col-desktop-md-1 geaui-col-desktop-xl-1"
            height="88px"
            width="88px">
          </p-skeleton>
        }
        <img
          class="icon geaui-col-desktop-xl-start-7 geaui-col-phone-sm-3 geaui-col-phone-lg-3 geaui-col-tablet-lg-2 geaui-col-tablet-sm-2 geaui-col-desktop-md-1"
          *ngIf="!loading"
          [src]="appData?.imagePath"
          [alt]="appData?.appKey" />
        <div
          class="geaui-col-desktop-xl-start-8 geaui-col-desktop-lg-6 geaui-col-phone-lg-7 geaui-col-desktop-md-6 geaui-col-phone-sm-6 geaui-col-tablet-lg-6 geaui-col-tablet-sm-6">
          <div class="gea-font-heading-32">
            {{ 'APPS.' + appId + '.WIDGETS.APP_LINK.NAME' | translate }}
          </div>
          <div class="summary">{{ 'APPS.' + appId + '.DESCRIPTION' | translate }}</div>
        </div>
        <div class="geaui-col-desktop-xl-start-8 button-container geaui-col-desktop-md-start-2 geaui-col-desktop-md-7">
          <geaui-button-v2
            class="button"
            [disabled]="appData?.locked ?? false"
            [loading]="loading"
            [postIcon]="true"
            (click)="routeToApp()">
            {{ 'APP-DETAIL.GO-TO-APP' | translate }}
          </geaui-button-v2>
          <div
            [pTooltip]="'X.INFO.NO_ACCESS.RIGHT_MISSING' | translate"
            [tooltipDisabled]="hasVerificationPermission"
            tooltipPosition="bottom"
            tooltipStyleClass="p-tooltip-light-blue">
            <geaui-link-v2
              class="button request-access-link"
              *ngIf="appData?.locked"
              [disabled]="verificationRequested"
              [loading]="loading"
              [disabled]="!hasVerificationPermission || isVerificationPending()"
              [preIcon]="'check'"
              (click)="onRequestVerification()">
              {{
                isVerificationPending()
                  ? ('APP-DETAIL.VERIFY.PENDING' | translate)
                  : ('APP-DETAIL.VERIFY.REQUEST-ACCESS' | translate)
              }}
            </geaui-link-v2>
          </div>
        </div>
        <div
          class="app-detail-content geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4">
          <div class="geaui-col-desktop-md-12 geaui-col-desktop-md-start-1">
            <hr class="divider" />
          </div>
          @if (loading) {
            <div class="heading">
              <p-skeleton
                class="skeleton-gap geaui-col-phone-sm-4 geaui-col-phone-lg-8 geaui-col-tablet-sm-12 geaui-col-tablet-lg-12 geaui-col-desktop-md-6 geaui-col-desktop-xl-8"
                height="700px">
              </p-skeleton>
            </div>
          } @else {
            <div class="gea-font-heading-20 heading">
              {{ 'APP-DETAIL.SCREENSHOTS.HEADING' | translate }}
            </div>
            <div class="carousel-container geaui-col-desktop-md-12 geaui-col-desktop-xl-8">
              <p-carousel
                *ngIf="screenshotData"
                [value]="screenshotData"
                [numVisible]="3"
                [numScroll]="1"
                [circular]="false"
                [responsiveOptions]="responsiveOptions">
                <ng-template pTemplate="previousicon">
                  <geaui-icon-v2 file="16px_chevron-left"> </geaui-icon-v2>
                </ng-template>
                <ng-template pTemplate="nexticon">
                  <geaui-icon-v2 file="16px_chevron-right"> </geaui-icon-v2>
                </ng-template>
                <ng-template let-screenshot pTemplate="item">
                  <div class="screenshots-container" (click)="showGalleriaWithIndex(screenshot)">
                    <img
                      class="screenshot"
                      *ngIf="screenshot?.loaded"
                      [alt]="appData?.appKey"
                      (error)="onImageError(screenshot?.id)"
                      src="{{ screenshot?.url }}" />
                  </div>
                </ng-template>
              </p-carousel>
            </div>
            <div class="gea-font-heading-20 heading">
              {{ 'APP-DETAIL.DESCRIPTION.HEADING' | translate }}
            </div>
            <div class="description geaui-col-desktop-md-8" #appDetailDescription [ngClass]="{ truncated: !textExpanded }">
              {{ 'APP-DETAIL.' + appId + '.DESCRIPTION' | translate }}
            </div>
            <geaui-link-v2 *ngIf="hasShowMoreButton" (click)="toggleShowMore()">
              {{
                textExpanded ? ('APP-DETAIL.DESCRIPTION.SHOW-LESS' | translate) : ('APP-DETAIL.DESCRIPTION.SHOW-MORE' | translate)
              }}
            </geaui-link-v2>
            <div class="gea-font-heading-20 heading">
              {{ 'APP-DETAIL.KEY-FEATURES.HEADING' | translate }}
            </div>
            <div
              class="description geaui-col-desktop-md-8"
              [innerHTML]="'APP-DETAIL.' + appId + '.KEY-FEATURES' | translate"></div>
          }
        </div>
      </div>
    </div>
  </ng-container>
</geaui-page-content-layout>
<p-galleria
  [(visible)]="showGalleria"
  [value]="screenshotData"
  [containerStyle]="{ 'max-width': '80%' }"
  [activeIndex]="activeIndex"
  [numVisible]="3"
  [circular]="false"
  [fullScreen]="true"
  [showItemNavigators]="true"
  [showThumbnails]="false"
  [responsiveOptions]="responsiveOptions">
  <ng-template let-screenshot pTemplate="item">
    <img
      *ngIf="screenshot?.loaded"
      [alt]="appData?.appKey"
      (error)="onImageError(screenshot?.id)"
      style="width: 100%; display: block"
      src="{{ screenshot?.url }}" />
  </ng-template>
  <ng-template pTemplate="closeicon">
    <geaui-icon-v2 file="16px_close" color="white"> </geaui-icon-v2>
  </ng-template>
  <ng-template pTemplate="itempreviousicon">
    <geaui-icon-v2 file="16px_chevron-left" color="white"> </geaui-icon-v2>
  </ng-template>
  <ng-template pTemplate="itemnexticon">
    <geaui-icon-v2 file="16px_chevron-right" color="white"> </geaui-icon-v2>
  </ng-template>
</p-galleria>
