<div class="add-widget-container">
  <div class="geaui-grid">
    <div
      class="available-apps geaui-col-phone-sm-4 geaui-col-phone-lg-8 geaui-col-tablet-sm-12 geaui-col-tablet-lg-12 geaui-col-desktop-md-12 geaui-col-desktop-xl-24">
      <span class="title">{{ 'DASHBOARD.AVAILABLE-APPS.TITLE' | translate }}</span>
      <span class="description">{{ 'DASHBOARD.AVAILABLE-APPS.TEXT' | translate }}</span>
    </div>

    @if (loading) {
      @for (i of ' '.repeat(5); track $index) {
        <p-skeleton
          class="geaui-col-phone-sm-4 geaui-col-phone-lg-8 geaui-col-tablet-sm-12 geaui-col-tablet-lg-12 geaui-col-desktop-md-6 geaui-col-desktop-xl-8"
          height="100px"></p-skeleton>
      }
    }

    @for (app of appList; track $index) {
      <gea-id-workspace-app-tile
        class="geaui-col-phone-sm-4 geaui-col-phone-lg-8 geaui-col-tablet-sm-12 geaui-col-tablet-lg-12 geaui-col-desktop-md-6 geaui-col-desktop-xl-8"
        [isSelected]="selectedApp?.appKey === app.appKey"
        [key]="app.appKey"
        [description]="'APPS.' + app.appKey + '.DESCRIPTION' | translate"
        [imageLink]="app.imagePath"
        [openLink]="app.url"
        [toVerify]="false"
        [isRedirect]="false"
        (click)="app.widgetTypes.length ? selectWidget(app) : selectApp(app)">
      </gea-id-workspace-app-tile>
    }

    @if (!loading && (verifyAppList?.length ?? 0 >= 0)) {
      <div
        class="verify-apps geaui-col-phone-sm-4 geaui-col-phone-lg-8 geaui-col-tablet-sm-12 geaui-col-tablet-lg-12 geaui-col-desktop-md-12 geaui-col-desktop-xl-24">
        <div class="text-container">
          <span class="title">{{ 'DASHBOARD.VERIFY-APPS.TITLE' | translate }}</span>
          <span class="description">{{ 'DASHBOARD.VERIFY-APPS.TEXT' | translate }}</span>
        </div>
        <geaui-button-v2 [disabled]="!hasVerificationPermission" (click)="onStartVerification()">
          {{ 'DASHBOARD.VERIFY.UNLOCK-LIMITED-APPS' | translate }}
          <geaui-icon-v2 [file]="'16px_arrow-right'" />
        </geaui-button-v2>
      </div>
    }

    @for (app of verifyAppList; track $index) {
      <gea-id-workspace-app-tile
        class="app-tile geaui-col-phone-sm-4 geaui-col-phone-lg-8 geaui-col-tablet-sm-12 geaui-col-tablet-lg-12 geaui-col-desktop-md-6 geaui-col-desktop-xl-8"
        [key]="app.appKey"
        [description]="'APPS.' + app.appKey + '.DESCRIPTION' | translate"
        [imageLink]="app.imagePath"
        [toVerify]="true"></gea-id-workspace-app-tile>
    }
  </div>
</div>
