import {
  appendVisibilitySettings,
  ChipRendererComponent,
  ChipRendererConfig,
  ColumnDefinition,
  ColumnRendererComponent,
  TextFilterComponent,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';
import { UserInfoRendererComponent } from '../renderers/user-info-renderer/user-info-renderer.component';

export const usersColumnDefinitions: ColumnDefinition[] = appendVisibilitySettings([
  {
    displayName: 'X.LABEL.FIRST_NAME',
    key: 'firstName',
    renderer: {
      component: UserInfoRendererComponent as ColumnRendererComponent<unknown>,
    },
    visible: true,
  },
  {
    displayName: 'X.LABEL.LAST_NAME',
    key: 'lastName',
    renderer: {
      component: UserInfoRendererComponent as ColumnRendererComponent<unknown>,
    },
    visible: true,
  },
  createTextColumn('X.LABEL.EMAIL', 'email'),
  {
    ...createTextColumn('X.LABEL.CUSTOMER_USER_TYPE', 'customerUserType'),
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: 'X.CUSTOMER_USER_TYPE.',
      },
    },
  },
  createTextColumn('X.LABEL.MEMBERSHIPS', 'numberOfUserMemberships'),
  createTextColumn('USERS.LIST.INVITATIONS', 'numberOfInvitations'),
  {
    displayName: 'USERS.LIST.STATUS.HEADER',
    key: 'userListItemState',
    sortable: false,
    renderer: {
      component: ChipRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        mapping: {
          USER_ACTIVE: {
            severity: 'success',
            text: 'USERS.LIST.STATUS.ACTIVE',
          },
          USER_INACTIVE: {
            severity: 'default',
            text: 'USERS.LIST.STATUS.INACTIVE',
          },
          INVITATION_PENDING: {
            severity: 'information',
            text: 'USERS.LIST.STATUS.PENDING',
          },
          INVITATION_EXPIRED: {
            severity: 'error',
            text: 'USERS.LIST.STATUS.EXPIRED',
          },
        },
      } as ChipRendererConfig,
    },
  },
]);

function createTextColumn(displayName: string, key: string, filterable = false): ColumnDefinition {
  return {
    displayName,
    key,
    filter: filterable
      ? {
          component: TextFilterComponent,
        }
      : undefined,
    visible: true,
  };
}
