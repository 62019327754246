<gea-id-workspace-base-widget>
  <div class="default-widget-container">
    <geaui-icon-v2 class="default-icon" file="32px_app-store" color="blue"></geaui-icon-v2>
    <div class="text-container">
      <span class="heading" pTooltip="{{ 'DASHBOARD.DEFAULT.TITLE' | translate }}" type="main" geauiTooltipOnEllipsisV2>
        {{ 'DASHBOARD.DEFAULT.TITLE' | translate }}
      </span>
      <p class="description">{{ 'DASHBOARD.DEFAULT.DESCRIPTION' | translate }}</p>
    </div>
    <geaui-button-v2 [postIcon]="true" (click)="addWidget()" type="primary">
      {{ 'DASHBOARD.DEFAULT.ADD-WIDGET' | translate }}
    </geaui-button-v2>
  </div>
</gea-id-workspace-base-widget>
