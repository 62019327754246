<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-header>
    <geaui-page-header [pageTitle]="'PROFILE.PAGE-TITLE' | translate" [hasBackArrow]="false" [withSidebar]="true">
      <geaui-tab-menu-v2 [tabItems]="tabItems"> </geaui-tab-menu-v2>
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <router-outlet></router-outlet>
  </ng-container>
</geaui-page-content-layout>
