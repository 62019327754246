/**
 * Digital Platform - Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PutDashboardRequestWidgetsInnerDimension } from './putDashboardRequestWidgetsInnerDimension';
import { PutDashboardRequestWidgetsInnerPosition } from './putDashboardRequestWidgetsInnerPosition';


export interface PutDashboardRequestWidgetsInner { 
    id?: string;
    dimension: PutDashboardRequestWidgetsInnerDimension;
    position: PutDashboardRequestWidgetsInnerPosition;
}

