import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService, UserState } from '@gea/digital-ui-lib';

import { Store } from '@ngxs/store';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../environments/models/environment.model';

@Component({
  selector: 'gea-id-workspace-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  private country = 'DE';
  constructor(
    private translate: TranslateService,
    private gaService: GoogleAnalyticsService,
    private location: Location,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfiguration
  ) {}

  ngOnInit(): void {
    // This is the official syntax of ngxs
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.store.select(UserState.user).subscribe((user) => {
      if (user.country) {
        this.country = user.country;
      }
    });
    if (this.gaService.loggedInUser) {
      this.gaService.metaPush('Terms-and-Conditions');
    } else {
      // eslint-disable-next-line no-console
      console.warn('was not able to send ga event');
    }
    window.open(
      this.environment.storageURL +
        'terms/1/' +
        this.country +
        '/' +
        this.translate.currentLang?.split('-')[0]?.toUpperCase() +
        '/TermsAndConditions.pdf',
      '_blank'
    );
    this.location.back();
  }
}
