import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, NgModule } from '@angular/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';

import { NgxsModule } from '@ngxs/store';
import {
  ActionRequiredState,
  ApiService,
  AppEnvironmentState,
  BasicGridModule,
  ButtonV2Module,
  ComplexDialogV2Module,
  ContentLayoutComponent,
  DialogV2Module,
  GeaMissingTranslationHandler,
  GeaTranslateHttpLoader,
  HeaderConfig,
  HeaderModule,
  OfflineHeaderModule,
  IconModule,
  LinkV2Module,
  PageContentLayoutComponent,
  PageHeaderModule,
  PageLayoutModule,
  PermissionsState,
  SearchBarV2Module,
  SharedModule,
  SidebarV2Module,
  SnackbarModule,
  StepsService,
  TableV2Module,
  TabMenuV2Module,
  UserState,
  ContactFormSideModalModule,
  FeatureFlagConfigurationState,
} from '@gea/digital-ui-lib';
import { GeaIdConfiguration } from '@gea-id/shared';
import { MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';

import { AboutGeaComponent } from './about-gea/about-gea.component';
import { AddWidgetComponent } from './home/add-widget/add-widget.component';
import { AllAppsComponent } from './home/all-apps/all-apps.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppTileComponent } from './home/app-tile/app-tile.component';
import { BaseWidgetComponent } from './home/base-widget';
import { CookieListComponent } from './data-privacy/cookie-list/cookie-list.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { DashboardService } from './home/services/dashboard.service';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';
import { DefaultWidgetComponent } from './home/default-widget/default-widget.component';
import { HomeComponent } from './home/home.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AppCardWidgetComponent } from './home/app-card-widget/app-card-widget.component';
import { DashboardApiConfiguration } from './generated/dashboard-api/v1';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../environments/models/environment.model';
import { PortalAdministrationRoutingModule } from './administration/portal-administration-routing.module';
import { PortalAdministrationModule } from './administration/portal-administration.module';
import { NgIf } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { SvgIconComponent } from 'angular-svg-icon';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { IntroductionGuideModule } from './introduction-guide/introduction-guide.module';
import { AppDetailComponent } from './home/app-detail/app-detail.component';
import { CarouselModule } from 'primeng/carousel';
import { AccordionModule } from 'primeng/accordion';
import { GalleriaModule } from 'primeng/galleria';
import { HelpGuideComponent } from './help-guide/help-guide.component';

export function HttpLoaderFactory(http: HttpClient, environment: EnvironmentConfiguration) {
  return new GeaTranslateHttpLoader(http, environment.localizationStorageURL, ['i18n/1/portal/']);
}

export const HEADER_CONFIG = new InjectionToken<HeaderConfig>('HeaderConfig');

@NgModule({
  declarations: [
    HomeComponent,
    AppComponent,
    AppTileComponent,
    AllAppsComponent,
    AppDetailComponent,
    DashboardComponent,
    AppCardWidgetComponent,
    BaseWidgetComponent,
    AddWidgetComponent,
    AboutGeaComponent,
    DataPrivacyComponent,
    TermsAndConditionsComponent,
    CookieListComponent,
    DefaultWidgetComponent,
    HelpGuideComponent,
  ],
  imports: [
    NgxsModule.forRoot([UserState, ActionRequiredState, AppEnvironmentState, PermissionsState, FeatureFlagConfigurationState], {
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    AppRoutingModule,
    AccordionModule,
    BasicGridModule,
    BrowserModule,
    BrowserAnimationsModule,
    SidebarV2Module,
    TooltipModule,
    LinkV2Module,
    BrowserAnimationsModule,
    MsalModule,
    TranslateModule.forRoot({
      extend: true,
      useDefaultLang: false,
      defaultLanguage: 'en-US',
      loader: {
        deps: [HttpClient, ENVIRONMENT_CONFIG],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: GeaMissingTranslationHandler,
      },
    }),
    HeaderModule.forRoot(HEADER_CONFIG),
    OfflineHeaderModule,
    BasicGridModule,
    GalleriaModule,
    CarouselModule,
    SharedModule,
    PageLayoutModule,
    PortalAdministrationRoutingModule,
    PortalAdministrationModule,
    ContentLayoutComponent,
    ButtonV2Module,
    TableV2Module,
    TranslateModule,
    SearchBarV2Module,
    IconModule,
    NgIf,
    SkeletonModule,
    SvgIconComponent,
    ComplexDialogV2Module,
    TabMenuV2Module,
    PageHeaderModule,
    PageContentLayoutComponent,
    ProgressSpinnerModule,
    IntroductionGuideModule,
    DialogV2Module,
    SnackbarModule,
    ContactFormSideModalModule,
  ],
  providers: [
    {
      provide: 'baseUrl',
      useFactory: (env: EnvironmentConfiguration) => env.portal.baseURL ?? env.baseURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'backendInfoUrl',
      useFactory: (env: EnvironmentConfiguration) => (env.portal.baseURL ?? env.baseURL) + 'geaid/v1/actuator/info',
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'redirectUrl',
      useFactory: (env: EnvironmentConfiguration) => env.portal.redirectURL ?? env.baseURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'registrationBaseURL',
      useFactory: (env: EnvironmentConfiguration) => env.registrationBaseURL ?? env.baseURL + 'registration-service/v1/',
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'iconPath',
      useFactory: (env: EnvironmentConfiguration) => env.iconStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'appContentPath',
      useFactory: (env: EnvironmentConfiguration) => env.appContentStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'storageURL',
      useFactory: (env: EnvironmentConfiguration) => env.localizationStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: GeaIdConfiguration,
      useFactory: (auth: ApiService, baseUrl: string) =>
        new GeaIdConfiguration({
          basePath: baseUrl + 'geaid/v1',
          accessToken: auth.getAccessToken.bind(auth),
        }),
      deps: [ApiService, 'baseUrl'],
      multi: false,
    },
    {
      provide: DashboardApiConfiguration,
      useFactory: (auth: ApiService, baseUrl: string) =>
        new DashboardApiConfiguration({
          basePath: baseUrl + 'application',
          accessToken: auth.getAccessToken.bind(auth),
        }),
      deps: [ApiService, 'baseUrl'],
      multi: false,
    },
    provideHttpClient(),
    TranslatePipe,
    StepsService,
    DashboardService,
    MsalService,
    { provide: 'googleTagManagerId', useValue: 'GTM-M64DB78' },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
