<div class="cookie-container">
  <!-- OneTrust Cookies List start -->
  <div id="ot-sdk-cookie-policy"></div>
  <!-- OneTrust Cookies List end -->
</div>
<!-- OneTrust Cookies Consent Notice -->
<script
  src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
  type="text/javascript"
  charset="UTF-8"
  data-domain-script="81b19338-ad19-47e9-92ba-cf50c4de3d36"></script>
<script type="text/javascript">
  function OptanonWrapper() {}
</script>
<!-- OneTrust Cookies Consent Notice end -->
