import { NgModule } from '@angular/core';
import { PortalUsersListPageComponent } from './user-list/portal-users-list-page.component';
import { PortalAdministrationComponent } from './portal-administration.component';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import {
  AutocompleteSelectV2Module,
  ButtonV2Module,
  ComplexDialogV2Module,
  ContentLayoutComponent,
  DialogV2Module,
  IconModule,
  InputV2Module,
  LinkV2Module,
  MultiSelectV2Module,
  PageContentLayoutComponent,
  PageFooterComponent,
  PageHeaderModule,
  SearchBarV2Module,
  SelectV2Module,
  SnackbarModule,
  TableV2Module,
  TabMenuV2Module,
  ToggleV2Module,
  TooltipV2Module,
  WizardV2Module,
} from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { PortalMembershipsListPageComponent } from './user-list/user-detail/memberships-list/portal-memberships-list-page.component';
import { TooltipModule } from 'primeng/tooltip';
import { PortalMembershipDialogComponent } from './user-list/user-detail/memberships-list/membership-dialog/portal-membership-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PortalUserDetailComponent } from './user-list/user-detail/portal-user-detail.component';
import { PortalEditUserFormComponent } from './user-list/user-detail/edit-user-form/portal-edit-user-form.component';
import { DividerModule } from 'primeng/divider';
import { DiscardChangesGuard } from '@gea-id/shared';
import { PortalUserInviteComponent } from './user-list/user-invite/portal-user-invite.component';
import { PortalUserInviteFormComponent } from './user-list/user-invite/user-invite-form/portal-user-invite-form.component';
import { PortalUserInviteMembershipDialogComponent } from './user-list/user-invite/user-invite-membership-list/user-invite-membership-dialog/portal-user-invite-membership-dialog.component';
import { PortalUserInviteMembershipListComponent } from './user-list/user-invite/user-invite-membership-list/portal-user-invite-membership-list.component';
import { ProfileComponent } from './profile/profile.component';
import { MembershipsComponent } from './profile/memberships/memberships.component';
import { PersonalDataComponent } from './profile/personal-data/personal-data.component';
import { ActionsRendererComponent } from './profile/memberships/actions-renderer/actions-renderer.component';
import { UserInfoRendererComponent } from './user-list/renderers/user-info-renderer/user-info-renderer.component';
import { OrganizationsModule } from './organizations/organizations.module';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

@NgModule({
  declarations: [
    PortalUsersListPageComponent,
    PortalAdministrationComponent,
    PortalUserDetailComponent,
    PortalMembershipsListPageComponent,
    PortalMembershipDialogComponent,
    PortalEditUserFormComponent,
    PortalMembershipDialogComponent,
    PortalUserInviteComponent,
    PortalUserInviteFormComponent,
    PortalUserInviteMembershipDialogComponent,
    PortalUserInviteMembershipListComponent,
    ProfileComponent,
    MembershipsComponent,
    NotificationSettingsComponent,
    PersonalDataComponent,
    ActionsRendererComponent,
    UserInfoRendererComponent,
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    ReactiveFormsModule,
    ContentLayoutComponent,
    TableV2Module,
    TranslateModule,
    ButtonV2Module,
    SearchBarV2Module,
    TabMenuV2Module,
    IconModule,
    TooltipModule,
    AutocompleteSelectV2Module,
    LinkV2Module,
    PageHeaderModule,
    InputV2Module,
    ToggleV2Module,
    DividerModule,
    DialogV2Module,
    ComplexDialogV2Module,
    SnackbarModule,
    WizardV2Module,
    TooltipV2Module,
    OrganizationsModule,
    SelectV2Module,
    MultiSelectV2Module,
    PageContentLayoutComponent,
    PageFooterComponent,
  ],
  providers: [DiscardChangesGuard],
  exports: [PortalAdministrationComponent],
})
export class PortalAdministrationModule {}
