import { ColumnDefinition, ColumnRendererComponent, Membership, TranslationRendererComponent } from '@gea/digital-ui-lib';
import { EditRendererComponent } from '@gea-id/shared';

export const MEMBERSHIP_EDIT_ACTION = 'membership-list-edit';

export const membershipsColumnDefinitions: ColumnDefinition[] = [
  {
    displayName: 'X.LABEL.ORGANISATION',
    key: 'organizationName',
    sortable: false,
  },
  {
    displayName: 'X.LABEL.ROLE',
    key: 'roleName',
    sortable: false,
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<Membership>,
      config: {
        prefix: 'X.ROLE.',
      },
    },
  },
  {
    displayName: '',
    key: 'edit',
    sortable: false,
    renderer: {
      component: EditRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        icon: '16px_edit',
        action: MEMBERSHIP_EDIT_ACTION,
      },
    },
  },
];
