<div class="dashboard-content">
  <geaui-grid
    class="grid"
    [storedWidgetSettings]="storedWidgetSettings"
    [widgetDefinitions]="widgetDefinitions"
    [apps]="apps"
    [googleAnalyticsActivated]="true"
    [hideEditButton]="true"
    [editModeActive]="editModeActive"
    (addWidgetClicked)="openAddWidget()"
    (saveGridClicked)="onSaveGrid($event)"
    (editModeUpdated)="editModeActive = $event"
    (editWidgetClicked)="editModeActive = !editModeActive">
  </geaui-grid>
</div>
