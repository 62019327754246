import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import {
  COMPLEX_DIALOG_INPUT_DATA,
  ComplexDialogEmbeddedView,
  ComplexDialogV2Service,
  DialogV2Service,
  SelectOption,
} from '@gea/digital-ui-lib';
import { FormBuilder, Validators } from '@angular/forms';
import { filter, first, map, Observable, tap } from 'rxjs';
import { OrgaData, UserInviteService } from '@gea-id/shared';
import { InviteMembership } from '@gea-id/shared';
import { CultureService } from '@gea-id/shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gea-id-user-invite-membership-dialog',
  templateUrl: './portal-user-invite-membership-dialog.component.html',
  styleUrl: './portal-user-invite-membership-dialog.component.scss',
})
export class PortalUserInviteMembershipDialogComponent implements ComplexDialogEmbeddedView, OnInit {
  protected readonly FORM_CONTROL_ORGANISATION = 'organisation';
  protected readonly FORM_CONTROL_ROLE = 'role';
  isAcceptDisabled = true;

  organisations: OrgaData[] = [];
  organisationOptions$: Observable<SelectOption<string>[]> = this.cultureService.organizations$.pipe(
    tap((orgas) => (this.organisations = orgas)),
    map((orgas) =>
      orgas.map(
        (orga) =>
          ({
            name: orga.name,
            value: orga.orgaId,
          }) as SelectOption<string>
      )
    )
  );
  loading = true;

  form = this.formBuilder.group({
    organisation: ['', [Validators.required]],
    role: ['', [Validators.required]],
  });
  protected roleOptions: SelectOption<string>[] = [];

  constructor(
    private complexDialogService: ComplexDialogV2Service,
    private formBuilder: FormBuilder,
    private userInviteService: UserInviteService,
    private dialogV2Service: DialogV2Service,
    private cultureService: CultureService,
    private destroyRef: DestroyRef,
    @Inject(COMPLEX_DIALOG_INPUT_DATA) public membershipData?: InviteMembership
  ) {}

  ngOnInit(): void {
    this.isAcceptDisabled = !this.membershipData;
    this.cultureService.isOrganizationLoaded$
      .pipe(
        filter((isLoaded) => isLoaded),
        tap(() => (this.loading = false)),
        first()
      )
      .subscribe(() => this.fillFormFields());

    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isAcceptDisabled = this.form.invalid;
      this.setRoleOptions();
    });
  }

  onAcceptClick() {
    this.complexDialogService.emitDataOutputForComponent({
      inviteMemberShipId: this.membershipData?.inviteMemberShipId,
      organisation: this.organisations.find((orga) => orga.orgaId === this.form.getRawValue().organisation),
      role: this.cultureService.roles.find((role) => role.id === this.form.getRawValue().role),
    });
    this.complexDialogService.close();
  }

  onDeleteMemberShip() {
    if (!this.membershipData) return;
    this.userInviteService.emitDeleteMembership(this.membershipData.inviteMemberShipId);
    this.complexDialogService.close();
    this.dialogV2Service.close();
  }

  private setRoleOptions(): void {
    const orga = this.organisations.find((o) => o.orgaId === this.form.value.organisation);

    this.cultureService.roles$
      .pipe(
        map((roles) =>
          roles
            .filter((role) => role.enabledOrgaTypes.includes(orga?.type ?? ''))
            .filter(
              (role) =>
                !this.userInviteService.membershipsData
                  .filter((membership) => orga?.orgaId === membership.organizationId)
                  .map((membership) => membership.roleId)
                  .includes(role.id) || role.id === this.membershipData?.roleId
            )
            .map((role) => {
              return {
                nameKey: 'X.ROLE.' + (role.name ?? '').toUpperCase(),
                value: role.id,
              } as SelectOption<string>;
            })
        )
      )
      .subscribe((roles) => {
        this.roleOptions = roles;
      });
  }

  private fillFormFields() {
    if (this.membershipData) {
      this.form.patchValue({
        organisation: this.membershipData?.organizationId,
        role: this.membershipData?.roleId,
      });
    }
  }
}
