<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-header>
    <geaui-page-header
      [withSidebar]="true"
      [pageTitle]="'ORGANIZATION.DETAIL.HEADER.TITLE.LABEL' | translate"
      (backArrowClicked)="routeOrgaList()">
      <geaui-tab-menu-v2 [tabItems]="(tabItems | async) ?? []" (activeItemChange)="changeTab($event)"></geaui-tab-menu-v2>
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <router-outlet></router-outlet>
  </ng-container>
</geaui-page-content-layout>
