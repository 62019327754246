<geaui-modal-v2 [show]="!!(show$ | async)" [headless]="true">
  <div class="onboarding-container" modal-headless-content>
    <section class="content">
      <img class="logo" src="./assets/images/introduction-guide/GEAPortalLogo.svg" />
      <div class="text-content" [innerHTML]="sanitizedSafeHtml$ | async"></div>
      <geaui-button-v2
        *ngIf="currentView.showAction"
        [postIcon]="true"
        (click)="currentView?.onClickAction ? currentView?.onClickAction() : undefined"
        >{{ currentView.actionText ?? '' | translate }}
      </geaui-button-v2>
    </section>
    <section class="image">
      <img [src]="currentView.image" />
      <geaui-icon-v2 (click)="onHandleCloseModal()" file="24px_close"></geaui-icon-v2>
      <div class="pagination-wrapper">
        <div class="pagination">
          <span class="index">0{{ currentIndex + 1 }}</span>
          <div class="slides">
            @for (view of views; track $index) {
              <div
                class="slide"
                [ngClass]="{ reached: currentIndex >= $index }"
                (click)="onHandleChangeCurrentIndex($index)"></div>
            }
          </div>
        </div>
        <div class="button-wrapper">
          @if (currentIndex > 0) {
            <div class="previous-slide" (click)="onHandleChangeCurrentIndex(currentIndex - 1)">
              <img src="./assets/images/introduction-guide/arrow-left.svg" />
            </div>
          }
          @if (currentIndex < views.length - 1) {
            <div class="next-slide" (click)="onHandleChangeCurrentIndex(currentIndex + 1)">
              <img src="./assets/images/introduction-guide/arrow-right.svg" />
            </div>
          }
        </div>
      </div>
    </section>
  </div>
</geaui-modal-v2>
