import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailService } from '@gea-id/shared';
import { MenuItem } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { FormService } from '@gea-id/shared';
import { ComponentCanDeactivate } from '@gea-id/shared';
import { CultureService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-workspace-user-detail',
  templateUrl: './portal-user-detail.component.html',
  styleUrl: './portal-user-detail.component.scss',
})
export class PortalUserDetailComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  userId = '';
  userName = '';
  isLoading = false;

  tabItems: MenuItem[] = [
    { id: '1', label: 'X.LABEL.PERSONAL_DATA' },
    { id: '2', label: 'X.LABEL.MEMBERSHIPS' },
  ];
  activeItem: MenuItem = this.tabItems[0];

  private unsubscribe$ = new Subject<void>();

  private readonly USER_LIST_PATH = 'administration/user';
  private readonly EDIT_USER_PATH = 'edit-user';
  private readonly MEMBERSHIPS_PATH = 'memberships';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userDetailService: UserDetailService,
    private cultureService: CultureService,
    private formService: FormService
  ) {}

  canDeactivate() {
    return !this.formService.areFormChanges;
  }

  ngOnInit() {
    this.isLoading = true;
    this.userDetailService.currentUserId = this.route.snapshot.params['userId'] as string;
    this.userId = this.userDetailService.currentUserId;
    this.initActiveTab();
    this.userDetailService.fetchProfileFormData();
    this.cultureService.loadResourcesData();
    this.userDetailService.fetchMemberships();
    this.userDetailService.userName$.pipe(takeUntil(this.unsubscribe$)).subscribe((userName) => {
      this.userName = userName;
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.formService.clearFormCache();
    this.formService.clearResourceCache();
    this.userDetailService.resetMemberships();
    this.userDetailService.resetProfileFormData();
    this.userDetailService.pageMemberShipList = 0;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeTab($event: MenuItem) {
    this.activeItem = $event;
    switch (this.activeItem.id) {
      case '2':
        this.routeMemberships();
        break;
      default:
        this.routeProfileUser();
        break;
    }
  }

  routeProfileUser() {
    void this.router.navigate([this.USER_LIST_PATH, this.userId, this.EDIT_USER_PATH]);
  }

  routeMemberships() {
    void this.router.navigate([this.USER_LIST_PATH, this.userId, this.MEMBERSHIPS_PATH]);
  }

  routeUserListPage() {
    void this.router.navigate([this.USER_LIST_PATH]);
  }

  private initActiveTab() {
    const path = this.route.snapshot.firstChild?.routeConfig?.path;
    switch (path) {
      case this.MEMBERSHIPS_PATH:
        this.activeItem = this.tabItems[1];
        break;
      default:
        this.activeItem = this.tabItems[0];
        break;
    }
  }
}
