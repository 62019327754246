<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-header>
    <geaui-page-header [pageTitle]="'NOTIFICATION-SETTINGS.TITLE' | translate" [hasBackArrow]="false" [withSidebar]="true">
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <div class="notification-settings-container">
      <div class="geaui-grid geaui-sidebar-grid">
        <div
          class="notification-channels-container geaui-sub-grid geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12 geaui-col-phone-lg-24">
          <div
            class="notification-channels-description geaui-col-desktop-md-8 geaui-col-desktop-lg-8 geaui-col-desktop-xl-8 geaui-col-phone-lg-16">
            <div class="notification-channels-description-title title">
              {{ 'NOTIFICATION-SETTINGS.CHANNEL-LABEL' | translate }}
            </div>
            <div class="notification-channels-description-info info">{{ 'NOTIFICATION-SETTINGS.CHANNEL-INFO' | translate }}</div>
          </div>
          <div
            class="notification-channels-settings geaui-col-desktop-xl-start-19 geaui-col-desktop-md-4 geaui-col-desktop-lg-4 geaui-col-desktop-xl-6 geaui-col-phone-lg-8">
            <geaui-toggle-v2
              class="notification-email"
              [initialValue]="settings.channelEmail"
              [loading]="loading"
              [isLabelLeft]="true"
              [label]="'X.LABEL.EMAIL' | translate"
              (toggled)="toggleChannelEmail($event)"></geaui-toggle-v2>
            <geaui-toggle-v2
              class="notification-push"
              [initialValue]="settings.channelInApp"
              [loading]="loading"
              [isLabelLeft]="true"
              [label]="'NOTIFICATION-SETTINGS.PUSH-LABEL' | translate"
              (toggled)="toggleChannelInApp($event)"></geaui-toggle-v2>
          </div>
        </div>
        <div
          class="equipment-filter-container geaui-sub-grid geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12 geaui-col-phone-lg-24">
          <div
            class="equipment-filter-description geaui-col-desktop-md-8 geaui-col-desktop-lg-8 geaui-col-desktop-xl-8 geaui-col-phone-lg-16">
            <div class="equipment-filter-title title">{{ 'NOTIFICATION-SETTINGS.EQUIPMENT-LABEL' | translate }}</div>
            <div class="equipment-filter-info info">{{ 'NOTIFICATION-SETTINGS.EQUIPMENT-INFO' | translate }}</div>
          </div>
          <geaui-multi-select-v2
            class="equipment-select geaui-col-desktop-xl-start-21 geaui-col-desktop-md-4 geaui-col-desktop-lg-4 geaui-col-desktop-xl-4 geaui-col-phone-lg-8"
            id="product"
            [hideSearchbar]="true"
            [group]="formGroup"
            [label]="'NOTIFICATION-SETTINGS.EQUIPMENT-SELECT' | translate"
            [options]="products"
            [loading]="loading"
            [selected]="selectedProducts"
            (selectChanged)="selectProduct($event)"
            (clearButtonClick)="clearProducts()">
          </geaui-multi-select-v2>
        </div>
        <div
          class="apps-settings-description container-padding geaui-col-desktop-xl-start-7 geaui-col-desktop-md-8 geaui-col-desktop-lg-8 geaui-col-desktop-xl-8 geaui-col-phone-lg-16">
          <div class="apps-settings-title title">{{ 'NOTIFICATION-SETTINGS.APP-SETTINGS.TITLE' | translate }}</div>
          <div class="apps-settings-info info">{{ 'NOTIFICATION-SETTINGS.APP-SETTINGS.INFO' | translate }}</div>
        </div>
        <div
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-4 geaui-col-desktop-lg-4 geaui-col-desktop-xl-4 geaui-col-phone-lg-8"></div>
        <div
          class="app-select-container geaui-sub-grid geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12 geaui-col-phone-lg-24">
          <geaui-select-v2
            class="app-select geaui-col-desktop-md-3 geaui-col-desktop-lg-3 geaui-col-desktop-xl-5 geaui-col-phone-lg-6"
            id="apps"
            [options]="apps"
            [group]="formGroup"
            [label]="'NOTIFICATION-SETTINGS.SELECT-APP' | translate"
            [loading]="loading"
            (selectChanged)="selectApp($event); setAllAppsToggleState()">
          </geaui-select-v2>
          <geaui-toggle-v2
            class="app-select-radio-button geaui-col-desktop-md-9 geaui-col-desktop-lg-9 geaui-col-desktop-xl-19 geaui-col-phone-lg-18"
            [initialValue]="allAppsToggleState"
            [isLabelLeft]="true"
            [loading]="loading"
            (toggled)="allAppsToggleState = $event; toggleAllApps()"
            label="Activate all">
          </geaui-toggle-v2>
        </div>

        <div
          class="generated-notification-container container-padding geaui-sub-grid geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-12 geaui-col-phone-lg-24">
          @for (notificationType of selectedApp?.notificationTypes; track notificationType) {
            <div
              class="generated-notification-description geaui-col-desktop-md-8 geaui-col-desktop-lg-8 geaui-col-desktop-xl-8 geaui-col-phone-lg-16">
              <div class="generated-notification-title">
                {{ 'UI-LIB.NOTIFICATION-SETTINGS.APP.' + selectedApp?.name + '.SETTING.' + notificationType.name | translate }}
              </div>
            </div>
            <geaui-toggle-v2
              class="generated-notification-radio-button geaui-col-desktop-xl-start-21 geaui-col-desktop-md-4 geaui-col-desktop-lg-4 geaui-col-desktop-xl-4 geaui-col-phone-lg-8"
              [initialValue]="notificationType.active"
              [disabled]="notificationType.disabled ?? false"
              (toggled)="notificationType.active = $event; setAllAppsToggleState()">
            </geaui-toggle-v2>
          }
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container page-footer>
    <geaui-page-footer [withSidebar]="true">
      <ng-container footer-button>
        <geaui-button-v2 [type]="'primary'" (click)="save()" data-cy="send-request">
          <span>{{ 'X.BUTTON.SAVE' | translate }}</span>
        </geaui-button-v2>
      </ng-container>
    </geaui-page-footer>
  </ng-container>
</geaui-page-content-layout>
