<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-content>
    <form class="form" [formGroup]="form">
      <section class="geaui-grid geaui-sidebar-grid">
        <div
          class="gea-font-heading-20 geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-1">
          {{ 'ORGANIZATION.DETAIL.INFO.BASIC.TITLE.LABEL' | translate }}
        </div>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-2"
          [required]="true"
          [loading]="loading"
          [group]="form"
          [floatLabelWhileDisabled]="true"
          [disabled]="hasNoUpdatePermission"
          label="X.LABEL.NAME"
          formName="orgaName">
        </geaui-input-v2>
        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-3"
          id="type"
          [formControl]="form.controls.type"
          [disabled]="!isNewOrga"
          [label]="'X.LABEL.TYPE' | translate"
          [loading]="loading"
          [options]="orgaTypes"
          formControlName="type">
        </geaui-autocomplete-select-v2>

        @if (!isNewOrga && (hasDeletePermission | async)) {
          <geaui-link-v2
            class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-7"
            [loading]="loading"
            (click)="deleteOrga()"
            preIcon="trash-can"
            type="cancel-red">
            {{ 'X.LABEL.DELETE' | translate }}
          </geaui-link-v2>
        }

        <!--  Address-->
        <div
          class="gea-font-heading-20 geaui-col-desktop-xl-start-12 geaui-col-desktop-md-start-6 geaui-col-desktop-md-6 geaui-row-desktop-md-start-1">
          {{ 'ORGANIZATION.DETAIL.INFO.ADDRESS.SHIPPING.TITLE.LABEL' | translate }}
        </div>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-12 geaui-col-desktop-md-start-6 geaui-col-desktop-md-3 geaui-row-desktop-md-start-2"
          [required]="true"
          [loading]="loading"
          [group]="form.controls.shippingAddress"
          [floatLabelWhileDisabled]="true"
          [disabled]="!isNewOrga"
          label="X.LABEL.STREET"
          formName="street">
        </geaui-input-v2>
        <geaui-input-v2
          class="gea-font-heading-22 geaui-col-desktop-xl-start-15 geaui-col-desktop-md-start-9 geaui-col-desktop-md-3 geaui-row-desktop-md-start-2"
          [required]="true"
          [loading]="loading"
          [group]="form.controls.shippingAddress"
          [floatLabelWhileDisabled]="true"
          [disabled]="!isNewOrga"
          label="X.LABEL.CITY"
          formName="city">
        </geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-12 geaui-col-desktop-md-start-6 geaui-col-desktop-md-3 geaui-row-desktop-md-start-3"
          [required]="true"
          [loading]="loading"
          [group]="form.controls.shippingAddress"
          [floatLabelWhileDisabled]="true"
          [disabled]="!isNewOrga"
          label="X.LABEL.ZIP"
          formName="zipCode">
        </geaui-input-v2>
        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-xl-start-15 geaui-col-desktop-md-start-9 geaui-col-desktop-md-3 geaui-row-desktop-md-start-3"
          id="address.country"
          [formControl]="form.controls.shippingAddress.controls.country"
          [required]="true"
          [loading]="loading"
          [options]="countries"
          [disabled]="!isNewOrga"
          label="X.LABEL.COUNTRY">
        </geaui-autocomplete-select-v2>
        <div
          class="geaui-col-desktop-xl-start-12 geaui-col-desktop-md-start-6 geaui-col-desktop-md-4 geaui-row-desktop-md-start-4">
          <geaui-checkbox-v2
            [loading]="loading"
            [form]="form"
            [disabled]="!isNewOrga"
            formName="billingAddressSame"
            label="ORGANIZATION.DETAIL.INFO.ADDRESS.BILLING.SAME.LABEL">
          </geaui-checkbox-v2>
        </div>

        <!--  Invoice -->
        <div
          class="gea-font-heading-20 geaui-col-desktop-xl-start-12 geaui-col-desktop-md-start-6 geaui-col-desktop-md-6 geaui-row-desktop-md-start-5">
          {{ 'ORGANIZATION.DETAIL.INFO.ADDRESS.BILLING.TITLE.LABEL' | translate }}
        </div>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-12 geaui-col-desktop-md-start-6 geaui-col-desktop-md-3 geaui-row-desktop-md-start-6"
          [floatLabelWhileDisabled]="isInvoiceDisabled || hasNoUpdatePermission"
          [loading]="loading"
          [group]="form.controls.billingAddress"
          [disabled]="isInvoiceDisabled || !isNewOrga"
          label="X.LABEL.STREET"
          formName="street">
        </geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-15 geaui-col-desktop-md-start-9 geaui-col-desktop-md-3 geaui-row-desktop-md-start-6"
          [floatLabelWhileDisabled]="isInvoiceDisabled || !isNewOrga"
          [loading]="loading"
          [group]="form.controls.billingAddress"
          [disabled]="isInvoiceDisabled || !isNewOrga"
          label="X.LABEL.CITY"
          formName="city">
        </geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-12 geaui-col-desktop-md-start-6 geaui-col-desktop-md-3 geaui-row-desktop-md-start-7"
          [loading]="loading"
          [floatLabelWhileDisabled]="isInvoiceDisabled || !isNewOrga"
          [group]="form.controls.billingAddress"
          [disabled]="isInvoiceDisabled || !isNewOrga"
          label="X.LABEL.ZIP"
          formName="zipCode">
        </geaui-input-v2>
        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-xl-start-15 geaui-col-desktop-md-start-9 geaui-col-desktop-md-3 geaui-row-desktop-md-start-7"
          id="invoice.country"
          [loading]="loading"
          [formControl]="form.controls.billingAddress.controls.country"
          [options]="countries"
          [disabled]="isInvoiceDisabled || !isNewOrga"
          label="X.LABEL.COUNTRY">
        </geaui-autocomplete-select-v2>
        @if (!isNewOrga) {
          <geaui-tooltip-v2
            class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-7"
            [showIcon]="true"
            type="blue">
            {{
              (hasNoUpdatePermission
                ? 'ORGANIZATION.DETAIL.TOOLTIP.NO_EDIT_PERMISSON'
                : 'ORGANIZATION.DETAIL.TOOLTIP.EDIT_PERMISSON'
              ) | translate
            }}
          </geaui-tooltip-v2>
        }
        <br />
      </section>
    </form>
  </ng-container>

  <ng-container page-footer>
    <geaui-page-footer [withSidebar]="true">
      <ng-container footer-button>
        <geaui-button-v2 [sending]="loading" [disabled]="form.invalid || loading || hasNoUpdatePermission" (click)="save()">
          {{ 'X.BUTTON.SAVE' | translate }}
        </geaui-button-v2>
      </ng-container>
    </geaui-page-footer>
  </ng-container>
</geaui-page-content-layout>
