import {
  ColumnDefinition,
  ColumnRendererComponent,
  IconRendererComponent,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';
import { TableData } from '../organization-detail/organization-business-relations/organization-business-relations.component';

const orgaPrefix = 'ORGANIZATION.DETAIL.RELATIONS.';
export const getOrganizationRelationsColumnConfig = (orgaId: string): ColumnDefinition[] => [
  {
    displayName: orgaPrefix + 'RELATIONS_TYPE.LABEL',
    key: 'label',
    minWidth: 300,
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: 'X.ROLE.',
      },
    },
  },
  {
    displayName: orgaPrefix + 'RELATED_ORGA_NAME.LABEL',
    key: 'displayedOrga',
    minWidth: 300,
  },
  {
    displayName: '',
    key: 'edit',
    renderer: {
      component: IconRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        mapping: {
          '': '16px_edit',
        },
        hiddenFunction: (rowData: TableData) => rowData.ownerOrganizationId === orgaId,
      },
    },
  },
];
