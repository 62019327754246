import { NgModule } from '@angular/core';
import { IntroductionGuideComponent } from './introduction-guide.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonV2Module, IconModule, ModalV2Module } from '@gea/digital-ui-lib';
import { IntroductionGuideService } from './introduction-guide.service';

@NgModule({
  declarations: [IntroductionGuideComponent],
  imports: [CommonModule, TranslateModule, ModalV2Module, IconModule, IconModule, IconModule, ButtonV2Module],
  providers: [IntroductionGuideService],
  exports: [IntroductionGuideComponent],
})
export class IntroductionGuideModule {}
