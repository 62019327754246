import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'gea-id-workspace-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  tabItems: MenuItem[] = [
    { id: '1', label: 'X.LABEL.PERSONAL_DATA', routerLink: 'personal-data' },
    { id: '2', label: 'X.LABEL.MEMBERSHIPS', routerLink: 'memberships' },
  ];
}
