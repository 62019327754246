<form class="memberships-dialog-controls" [formGroup]="form">
  <div class="margin-y">
    <geaui-autocomplete-select-v2
      [formControlName]="FORM_CONTROL_ORGANISATION"
      [label]="'X.LABEL.ORGANISATION'"
      [loading]="loading"
      [options]="organisationOptions$ | async">
    </geaui-autocomplete-select-v2>
  </div>
  <div class="margin-y">
    <geaui-autocomplete-select-v2
      [formControlName]="FORM_CONTROL_ROLE"
      [label]="'X.LABEL.ROLE'"
      [loading]="loading"
      [options]="roleOptions">
    </geaui-autocomplete-select-v2>
  </div>
  <section class="invitation-btn-container" *ngIf="membershipData">
    <span
      ><geaui-link-v2 [preIcon]="'trash-can'" [type]="'cancel-red'" (click)="onDeleteMemberShip()">{{
        'X.BUTTON.DELETE' | translate
      }}</geaui-link-v2></span
    >
  </section>
</form>
