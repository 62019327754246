import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DashboardApiService, DashboardDto, DashboardInfoListInner, PutDashboardRequest } from '../../generated/dashboard-api/v1';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private api: DashboardApiService) {}

  getDashboardInfo(applicationId: string): Observable<DashboardInfoListInner[]> {
    return this.api.getDashboardInfoList({ applicationId: applicationId });
  }

  getDashboard(applicationId: string, dashboardId: string): Observable<DashboardDto> {
    return this.api.getDashboard({ applicationId: applicationId, dashboardId: dashboardId });
  }

  createDashboard(applicationId: string, dashboard: PutDashboardRequest): Observable<DashboardDto> {
    return this.api.createDashboard({ applicationId: applicationId, putDashboardRequest: dashboard });
  }

  updateDashboard(applicationId: string, dashboard: DashboardDto): Observable<DashboardDto> {
    return this.api.updateDashboard({
      applicationId: applicationId,
      dashboardId: dashboard.id?.toString() || '',
      putDashboardRequest: dashboard,
    });
  }
}
