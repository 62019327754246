import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { ColumnDefinition, ComplexDialogV2Service, TableServiceV2, TableV2Functions } from '@gea/digital-ui-lib';
import { BusinessRelationsEditModalComponent } from './modals/edit-relations/business-relations-edit-modal.component';
import { map } from 'rxjs/operators';
import { Observable, tap } from 'rxjs';
import { OrganizationDetailService } from '../services/organization-detail.service';
import { Store } from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getOrganizationRelationsColumnConfig } from '../../models/organization-relations-column.config';
import {
  BusinessRelationResponseV1,
  OrganizationsCreateRelationRequestParams,
  OrganizationsUpdateRelationRequestParams,
} from '@gea-id/shared';

export type TableData = BusinessRelationResponseV1 & { displayedOrga: string };

@Component({
  selector: 'gea-id--organization-business-relations',
  templateUrl: './organization-business-relations.component.html',
  styleUrl: './organization-business-relations.component.scss',
})
export class OrganizationBusinessRelationsComponent implements OnInit {
  public readonly TABLE_ID = 'portal-organization-business-relations';
  public data$: Observable<TableData[]> = this.organizationService.relations$.pipe(
    tap((data) => (this.totalRecords = data.entryCount)),
    map(
      (result) =>
        result.pageEntries.map((data) => ({
          ...data,
          label: data.name.toUpperCase(),
          displayedOrga: data.ownerOrganizationId === this.orgaId ? data.relatedName : data.ownerName,
        })) ?? []
    )
  );
  public totalRecords = 0;
  public _columnDefinitions: ColumnDefinition[] = [];
  public loading = true;
  // ToDo based on if Admin role
  public hasNoUpdatePermission = true;
  public tableFunctions: TableV2Functions<TableData> = {};

  @Input() private orgaId = '';

  get columnDefinitions(): Observable<ColumnDefinition[]> {
    return this.canEdit.pipe(map((canEdit) => this._columnDefinitions.filter((column) => column.key !== 'edit' || canEdit)));
  }

  constructor(
    private complexDialog: ComplexDialogV2Service,
    private organizationService: OrganizationDetailService,
    private tableService: TableServiceV2,
    protected store: Store,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this._columnDefinitions = getOrganizationRelationsColumnConfig(this.orgaId);

    this.tableService.getFilterTableSettings(this.TABLE_ID).subscribe((filter) => {
      this.organizationService.initRelations(filter, this.orgaId, {
        preTap: () => (this.loading = true),
        postTap: () => (this.loading = false),
      });
      this.initUpdatePermission();
    });

    this.tableFunctions = {
      rowsClickableFunction: (row: TableData) => {
        return row.ownerOrganizationId === this.orgaId;
      },
    };
  }

  get canEdit(): Observable<boolean> {
    return this.organizationService.orgaType$.pipe(map((type) => !!type?.orgaFlags.includes('EDIT_RELATIONS')));
  }

  initUpdatePermission(): void {
    this.hasNoUpdatePermission = !this.organizationService.isUpdateable;
  }

  protected openAddRelationsModal() {
    this.complexDialog.open(
      {
        title: 'X.LABEL.ADD',
        yes: 'X.BUTTON.ADD',
        no: 'X.BUTTON.CANCEL',
        data: { orgaId: this.orgaId },
        confirmCallback: (data: unknown) => this.addRelation(data as OrganizationsUpdateRelationRequestParams),
      },
      BusinessRelationsEditModalComponent
    );
  }

  private addRelation(request: OrganizationsCreateRelationRequestParams) {
    this.loading = true;
    this.organizationService
      .addRelation(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => (this.loading = false));
  }

  protected openEditRelationsModal(relation: BusinessRelationResponseV1) {
    this.complexDialog.open(
      {
        title: 'X.LABEL.EDIT',
        yes: 'X.BUTTON.EDIT',
        no: 'X.BUTTON.CANCEL',
        data: { orgaId: this.orgaId, relation: relation },
        confirmCallback: (data: unknown) => {
          this.editRelation(data as OrganizationsUpdateRelationRequestParams);
        },
      },
      BusinessRelationsEditModalComponent
    );
  }

  private editRelation(request: OrganizationsUpdateRelationRequestParams) {
    this.loading = true;
    this.organizationService
      .editRelation(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => (this.loading = false));
  }
}
