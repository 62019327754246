import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ClarityScriptLoaderService {
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadAndInitializeScript(projectId: string, enabled: boolean, clarityLoaderLocation: string): void {
    if (!enabled) {
      return;
    }

    const scriptUrl = `${clarityLoaderLocation}?projectId=${encodeURIComponent(projectId)}`;
    const s = this.renderer.createElement('script') as HTMLScriptElement;
    s.type = 'text/javascript';
    s.src = scriptUrl;
    s.async = true;
    this.renderer.appendChild(this.document.head, s);
  }
}
