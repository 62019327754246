<div class="container">
  @if (rowData.state === MembershipState.ACCEPTED && !rowData.inherited && !isOwner) {
    <geaui-icon-v2 class="delete-icon" [color]="'black'" (click)="onDelete()" file="16px_remove"></geaui-icon-v2>
  }

  @if (rowData.state === MembershipState.PENDING) {
    <geaui-button-v2 class="btn" [sending]="false" (click)="onDecline()" type="tertiary" preIcon="close">{{
      'X.BUTTON.DECLINE' | translate
    }}</geaui-button-v2>
    <geaui-button-v2 class="btn" [sending]="false" (click)="onAccept()" preIcon="check">{{
      'X.BUTTON.ACCEPT' | translate
    }}</geaui-button-v2>
  }
</div>
