import { Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ColumnDefinition,
  ErrorHandlerV2Service,
  FilterTableSettings,
  PermissionKey,
  PermissionsState,
  TableServiceV2,
} from '@gea/digital-ui-lib';
import { first, Observable, startWith, Subscription } from 'rxjs';
import { organizationColumnConfig } from '../models/organizations-column.config';
import { Router } from '@angular/router';
import { NEW_ORGA_ID, orgaRoutes } from '../models/organizations.routing';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { OrganizationDetailService } from '../organization-detail/services/organization-detail.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrgaData, OrganizationService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-organizations-list-page',
  styleUrl: './organizations-list-page.component.scss',
  templateUrl: './organizations-list-page.component.html',
})
export class OrganizationsListPageComponent implements OnInit, OnDestroy {
  public TABLE_ID = 'portal-organization-table';
  public data: OrgaData[] = [];
  public columnDefinitions: ColumnDefinition[] = organizationColumnConfig;
  public totalRecords = 0;
  public searchInput = '';
  public loading = true;
  public subs: Subscription[] = [];

  private tableSettingsUpdated = false;

  constructor(
    private organizationService: OrganizationService,
    private orgaDetailService: OrganizationDetailService,
    private store: Store,
    private tableService: TableServiceV2,
    private errorHandler: ErrorHandlerV2Service,
    private router: Router,
    private _destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.tableService
        .getFilterTableSettings(this.TABLE_ID)
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe((filter) => this.fetchData(filter))
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  get hasNoCreatePermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      startWith([] as PermissionKey[]),
      map((permissions) => {
        return !permissions.includes(PermissionKey.CREATE_ORGANIZATION);
      })
    );
  }

  fetchData(filter: FilterTableSettings) {
    this.loading = true;
    this.organizationService
      .getOrganizationsV2({ ...filter })
      .pipe(first(), takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (data) => {
          this.data = data.pageEntries;
          this.totalRecords = data.entryCount;
          this.loading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
          this.loading = false;
        },
      });
  }

  public edit(rowData: OrgaData) {
    this.orgaDetailService.isUpdateable = rowData?.updatable ?? false;
    void this.router.navigate(['administration', 'organization', rowData.orgaId, orgaRoutes.BASIC_INFO]);
  }

  public add() {
    void this.router.navigate(['administration', 'organization', NEW_ORGA_ID, orgaRoutes.BASIC_INFO]);
  }

  clearSearch() {
    if (this.tableSettingsUpdated) {
      this.searchInput = '';
      this.clearTableSettings();
    }
  }

  onSearchOrganisationByClickEnter($event: KeyboardEvent) {
    if ($event.code !== 'Enter') return;
    this.onSearchOrganisations();
  }

  onSearchOrganisations() {
    if (!this.searchInput) {
      this.clearSearch();
      return;
    }
    this.updateTableSettings();
  }

  private updateTableSettings() {
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      search: this.searchInput !== '',
      searchValue: this.searchInput,
      page: 0,
    });
    this.tableSettingsUpdated = true;
  }

  private clearTableSettings() {
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      search: false,
      searchValue: '',
    });
    this.tableSettingsUpdated = false;
  }
}
