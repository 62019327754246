import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ColumnDefinition,
  ErrorHandlerV2Service,
  FilterTableSettings,
  PermissionKey,
  PermissionsState,
  TableServiceV2,
  User,
} from '@gea/digital-ui-lib';
import { usersColumnDefinitions } from './models/users-list-column-definitions.config';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UserService } from '@gea-id/shared';

@Component({
  selector: 'gea-id-users-list-page',
  templateUrl: './portal-users-list-page.component.html',
  styleUrl: './portal-users-list-page.component.scss',
})
export class PortalUsersListPageComponent implements OnInit {
  public TABLE_ID = 'portal-users-table';
  public users: User[] = [];
  public columnDefinitions: ColumnDefinition[] = usersColumnDefinitions;
  public totalRecords = 0;
  public searchInput = '';
  public loading = true;

  private tableSettingsUpdated = false;
  private readonly USER_LIST_PATH = 'administration/user';

  constructor(
    private usersService: UserService,
    private tableService: TableServiceV2,
    private router: Router,
    private store: Store,
    private errorHandler: ErrorHandlerV2Service,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((filter: FilterTableSettings) => {
        this.searchInput = filter.searchValue ?? '';
        this.tableSettingsUpdated = !!this.searchInput;
        this.fetchData(filter);
      });
  }

  fetchData(filter: FilterTableSettings) {
    this.loading = true;
    this.usersService
      .getAllUsersV2(filter)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (page) => {
          this.users = page.pageEntries as User[];
          this.totalRecords = page.entryCount;
          this.loading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
          this.loading = false;
        },
      });
  }

  onSearchUsers() {
    if (!this.searchInput) {
      this.clearSearch();
      return;
    }
    this.updateTableSettings();
  }

  onSearchUsersByClickEnter($event: KeyboardEvent) {
    if ($event.code !== 'Enter') return;
    this.onSearchUsers();
  }

  clearSearch() {
    if (this.tableSettingsUpdated) {
      this.searchInput = '';
      this.clearTableSettings();
    }
  }

  editUser(event: User) {
    void this.router.navigate([`${this.USER_LIST_PATH}/${event.id}/edit-user`]);
  }

  addUser() {
    void this.router.navigate([`${this.USER_LIST_PATH}/user-invite`]);
  }

  get hasNoCreatePermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      map((permissions) => {
        return !permissions.includes(PermissionKey.CREATE_USER_INVITATION);
      })
    );
  }

  private updateTableSettings() {
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      search: this.searchInput !== '',
      searchValue: this.searchInput,
      page: 0,
    });
    this.tableSettingsUpdated = true;
  }

  private clearTableSettings() {
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      search: false,
      searchValue: '',
    });
    this.tableSettingsUpdated = false;
  }
}
