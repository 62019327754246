export interface WidgetStyle {
  backgroundColor: string;
  iconBackgroundColor: string;
}

export const widgetStyleConfig: { [widgetType: string]: WidgetStyle; default: WidgetStyle } = {
  'ft_dairynetcloud_app-card': {
    backgroundColor: 'var(--geaui-gradient-green)',
    iconBackgroundColor: 'var(--geaui-background-green)',
  },
  'core_service-call_app-card': {
    backgroundColor: 'var(--geaui-gradient-energetic-pink)',
    iconBackgroundColor: 'var(--geaui-background-pink)',
  },
  'core_support_app-card': {
    backgroundColor: 'var(--geaui-gradient-energetic-pink)',
    iconBackgroundColor: 'var(--geaui-background-pink)',
  },
  'core_asset_app-card': {
    backgroundColor: 'var(--geaui-gradient-midnight-blue)',
    iconBackgroundColor: 'var(--geaui-background-grey)',
  },
  'core_document_app-card': {
    backgroundColor: 'var(--geaui-gradient-midnight-blue)',
    iconBackgroundColor: 'var(--geaui-background-grey)',
  },
  'lpt_codexassetcare_app-card': {
    backgroundColor: 'var(--geaui-gradient-midnight-blue)',
    iconBackgroundColor: 'var(--geaui-background-grey)',
  },
  eCatalog: {
    backgroundColor: 'var(--geaui-gradient-active-blue)',
    iconBackgroundColor: 'var(--geaui-background-active-blue)',
  },
  default: {
    backgroundColor: 'var(--geaui-gradient-vibrant-blue)',
    iconBackgroundColor: 'var(--geaui-background-vibrant-blue)',
  },
};
