import { Component } from '@angular/core';
import { ColumnRendererComponent, Membership, MembershipState, TableServiceV2 } from '@gea/digital-ui-lib';
import { OWNER_ROLE_ID } from '@gea-id/shared';

@Component({
  selector: 'gea-id-workspace-actions-renderer',
  templateUrl: './actions-renderer.component.html',
  styleUrl: './actions-renderer.component.scss',
})
export class ActionsRendererComponent implements ColumnRendererComponent<Membership> {
  rowData = {} as Membership;
  columnKey = '';
  tableId?: string;

  protected MembershipState = MembershipState;

  constructor(private tableService: TableServiceV2) {}

  get isOwner() {
    return this.rowData.roleId === OWNER_ROLE_ID;
  }

  onDelete() {
    if (this.tableId) {
      this.tableService.publishAction(this.tableId, 'delete-membership', this.rowData.id);
    }
  }

  onAccept() {
    if (this.tableId) {
      this.tableService.publishAction(this.tableId, 'accept-membership', this.rowData.id);
    }
  }

  onDecline() {
    if (this.tableId) {
      this.tableService.publishAction(this.tableId, 'decline-membership', this.rowData.id);
    }
  }
}
