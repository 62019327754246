/**
 * Digital Platform - Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DashboardDtoWidgetsInnerPosition } from './dashboardDtoWidgetsInnerPosition';
import { DashboardDtoWidgetsInnerDimension } from './dashboardDtoWidgetsInnerDimension';


export interface DashboardDtoWidgetsInner { 
    id: string;
    type: string;
    dimension: DashboardDtoWidgetsInnerDimension;
    position: DashboardDtoWidgetsInnerPosition;
    disabled?: boolean;
}

