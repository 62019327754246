import { Component } from '@angular/core';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-id-workspace-user-info-renderer',
  templateUrl: './user-info-renderer.component.html',
  styleUrl: './user-info-renderer.component.scss',
})
export class UserInfoRendererComponent<T extends { [key: string]: never }> implements ColumnRendererComponent<T> {
  rowData: T = {} as T;
  columnKey = '';
}
