import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  ColumnDefinition,
  ComplexDialogV2Service,
  MembershipState,
  TableServiceV2,
  WizardStep,
  WizardV2Service,
} from '@gea/digital-ui-lib';
import { MEMBERSHIP_EDIT_ACTION, membershipsColumnDefinitions } from '../../models/invite-memberships-column-definitions.config';
import { filter, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrgaData, RoleWithOrgaType, UserInviteService } from '@gea-id/shared';
import { PortalUserInviteMembershipDialogComponent } from './user-invite-membership-dialog/portal-user-invite-membership-dialog.component';
import { InviteMembership } from '@gea-id/shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gea-id-workspace-user-invite-membership-list',
  templateUrl: './portal-user-invite-membership-list.component.html',
  styleUrl: './portal-user-invite-membership-list.component.scss',
})
export class PortalUserInviteMembershipListComponent implements OnInit {
  public membershipTableData: InviteMembership[] = [];
  public columnDefinitions: ColumnDefinition[] = membershipsColumnDefinitions;
  public totalRecords = 0;
  protected TABLE_ID = 'user-invite-membership-list';
  private wizardStep?: WizardStep;

  constructor(
    private tableService: TableServiceV2,
    private complexDialogService: ComplexDialogV2Service,
    private wizardService: WizardV2Service,
    private userInviteService: UserInviteService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.tableService.actions
      .pipe(
        filter((action) => action.tableId === this.TABLE_ID && action.action === MEMBERSHIP_EDIT_ACTION),
        map(({ rowData }) => rowData as InviteMembership)
      )
      .subscribe((rowData) => this.openEditMemberShipDialog(rowData));

    this.wizardService.currentStep$
      .pipe(
        filter((step) => step.stepIndex === 2),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((step) => {
        this.wizardStep = step;
      });

    this.userInviteService
      .deleteMembershipAction$()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((inviteMemberShipId) => this.removeMembership(inviteMemberShipId))
      )
      .subscribe(() => this.updateWizardStep());
  }

  openAddMemberShipDialog() {
    this.complexDialogService.open(
      {
        title: 'X.LABEL.MEMBERSHIPS',
        yes: 'X.BUTTON.ADD',
        no: 'X.BUTTON.CANCEL',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        confirmCallback: (data: unknown) => this.addMembership(data as { organisation: OrgaData; role: RoleWithOrgaType }),
      },
      PortalUserInviteMembershipDialogComponent
    );
  }

  openEditMemberShipDialog = (membershipRowData: InviteMembership) => {
    this.complexDialogService.open(
      {
        title: 'X.LABEL.EDIT',
        yes: 'X.BUTTON.SAVE',
        no: 'X.BUTTON.CANCEL',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        confirmCallback: (data: unknown) =>
          this.editMembership(data as { inviteMemberShipId: string; organisation: OrgaData; role: RoleWithOrgaType }),
        data: membershipRowData,
      },
      PortalUserInviteMembershipDialogComponent
    );
  };

  private addMembership(data: { organisation: OrgaData; role: RoleWithOrgaType }) {
    const membershipToAdd = this.createMembershipFromOrgaAndRoleData(data);
    const membershipExists = !!this.membershipTableData.find(
      (membership) => membership.organizationId === membershipToAdd.organizationId && membership.roleId === membershipToAdd.roleId
    );
    if (!membershipExists) {
      this.membershipTableData = [...this.membershipTableData, membershipToAdd];
      this.userInviteService.membershipsData = [...this.membershipTableData];
      this.updateWizardStep();
    }
  }

  private editMembership(data: { inviteMemberShipId: string; organisation: OrgaData; role: RoleWithOrgaType }) {
    const updateMembershipData = this.createMembershipFromOrgaAndRoleData(data);
    const index = this.membershipTableData.findIndex((membership) => {
      return membership.inviteMemberShipId === updateMembershipData.inviteMemberShipId;
    });
    if (index === -1) {
      return;
    }
    const membership = this.membershipTableData[index];

    this.membershipTableData[index] = {
      ...membership,
      organizationId: updateMembershipData.organizationId,
      organizationName: updateMembershipData.organizationName,
      roleName: updateMembershipData.roleName,
      roleId: updateMembershipData.roleId,
    };
    this.userInviteService.membershipsData = [...this.membershipTableData];
  }

  private createMembershipFromOrgaAndRoleData({
    inviteMemberShipId,
    organisation,
    role,
  }: {
    inviteMemberShipId?: string;
    organisation: OrgaData;
    role: RoleWithOrgaType;
  }): InviteMembership {
    return {
      inviteMemberShipId: inviteMemberShipId ?? new Date().getTime().toString(), // we have to provide a unique id to identify the membership
      organizationId: organisation.orgaId,
      organizationName: organisation.name || '',
      state: MembershipState.PENDING,
      roleName: (role.name || '').toUpperCase(),
      roleId: role.id,
    };
  }

  private removeMembership(inviteMemberShipId: string): void {
    this.membershipTableData = this.membershipTableData.filter(
      (membership) => membership.inviteMemberShipId !== inviteMemberShipId
    );
    this.userInviteService.membershipsData = [...this.membershipTableData];
  }

  private updateWizardStep() {
    if (!this.wizardStep) return;

    if (this.membershipTableData.length > 0) {
      this.wizardService.updateWizardStep({ ...this.wizardStep, nextButtonDisabled: false, isCompleted: true });
    } else {
      this.wizardService.updateWizardStep({ ...this.wizardStep, nextButtonDisabled: true, isCompleted: false });
    }
  }
}
