import {
  appendVisibilitySettings,
  ColumnDefinition,
  ColumnRendererComponent,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';

const orgaPrefix = 'X.LABEL.';

export const organizationColumnConfig: ColumnDefinition[] = appendVisibilitySettings([
  {
    displayName: orgaPrefix + 'NAME',
    key: 'name',
  },
  {
    displayName: orgaPrefix + 'TYPE',
    key: 'type',
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: 'X.ORGA_TYPE.',
      },
    },
  },
  {
    displayName: orgaPrefix + 'COUNTRY',
    key: 'address.country',
    //ToDo nach CE-5136 wieder aktivieren
    sortable: false,
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: 'COUNTRIES.',
      },
    },
  },
  {
    displayName: orgaPrefix + 'CITY',
    key: 'address.city',
    //ToDo nach CE-5136 wieder aktivieren
    sortable: false,
  },
  {
    displayName: orgaPrefix + 'ZIP',
    key: 'address.zipCode',
    //ToDo nach CE-5136 wieder aktivieren
    sortable: false,
  },
  {
    displayName: orgaPrefix + 'STREET',
    key: 'address.street',
    //ToDo nach CE-5136 wieder aktivieren
    sortable: false,
  },
]);
