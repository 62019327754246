<form [formGroup]="form">
  <div class="flex">
    <geaui-autocomplete-select-v2
      class="geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-3"
      [required]="true"
      [options]="relationTypeOptions"
      [loading]="loading"
      [formControl]="form.controls.relationType"
      [label]="'ORGANIZATION.DETAIL.RELATIONS.ADD.RELATION.LABEL' | translate"
      formControlName="relationType">
    </geaui-autocomplete-select-v2>

    <geaui-autocomplete-select-v2
      class="geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-3"
      [required]="true"
      [emptyMessageKey]="emptyMessageKey"
      [preFilter]="fetchOrgas"
      [options]="possibleTargets"
      [loading]="loading"
      [formControl]="form.controls.relatedOrgaId"
      [label]="'X.LABEL.ORGANISATION' | translate"
      formControlName="relatedOrgaId">
    </geaui-autocomplete-select-v2>
    @if (isEditing) {
      <geaui-link-v2
        class="delete-button geaui-col-desktop-md-start-1 geaui-col-desktop-md-4 geaui-row-desktop-md-start-7"
        [loading]="loading"
        (click)="deleteRelation()"
        preIcon="trash-can"
        type="cancel-red">
        {{ 'X.BUTTON.DELETE' | translate }}
      </geaui-link-v2>
    }
  </div>
</form>
