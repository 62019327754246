import { DestroyRef, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserState } from '@gea/digital-ui-lib';
import { concatMap, filter } from 'rxjs';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../environments/models/environment.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LegalDocumentsService {
  currentLanguage = '';
  userCountry = 'DE';

  constructor(
    private translate: TranslateService,
    private store: Store,
    private destroyed: DestroyRef,
    private apiService: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration
  ) {
    this.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyed)).subscribe((event) => {
      this.currentLanguage = event.lang;
    });

    this.store
      // This is the official syntax of ngxs
      // eslint-disable-next-line @typescript-eslint/unbound-method
      .select(UserState.token)
      .pipe(
        filter((token) => !!token),
        // This is the official syntax of ngxs
        // eslint-disable-next-line @typescript-eslint/unbound-method
        concatMap(() => this.store.select(UserState.user))
      )
      .pipe(filter((user) => !!user.email))
      .subscribe((user) => {
        if (user?.country) {
          this.userCountry = user.country;
        }
      });
  }

  private getLanguagePath(currentLang: string = this.currentLanguage) {
    const lang = currentLang.includes('-') ? currentLang.split('-')[0] : currentLang;
    return this.environment.storageURL + 'terms/1/' + this.userCountry + '/' + lang.toUpperCase();
  }

  openDataprivacy() {
    this.openWindow(this.getLanguagePath() + '/DataPrivacy.pdf', this.getLanguagePath('EN') + '/DataPrivacy.pdf');
  }

  openTermsAndConditions() {
    this.openWindow(this.getLanguagePath() + '/TermsAndConditions.pdf', this.getLanguagePath('EN') + '/TermsAndConditions.pdf');
  }

  openImprint() {
    this.openWindow(
      this.environment.storageURL + 'imprint/Imprint' + this.currentLanguage + '.pdf',
      this.environment.storageURL + 'imprint/Imprinten-US.pdf'
    );
  }

  openCookieSettings() {
    // this syntax is necessary here to access onetrust
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.OneTrust.ToggleInfoDisplay();
  }

  private openWindow(url: string, fallback: string) {
    const newWindow = window.open('', '_blank') as Window;
    this.apiService.get(url, { responseType: 'blob' }).subscribe({
      next: () => {
        newWindow.location.href = url;
      },
      error: () => {
        newWindow.location.href = fallback;
      },
    });
  }
}
