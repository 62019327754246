import { Component, OnInit } from '@angular/core';

import { take } from 'rxjs';
import { App, ComplexDialogV2Service, GoogleAnalyticsService, WidgetDefinitions, WidgetSetting } from '@gea/digital-ui-lib';

import { AddWidgetComponent } from '../add-widget/add-widget.component';
import { DashboardService } from '../services/dashboard.service';
import { DefaultWidgetComponent } from '../default-widget/default-widget.component';
import { AppCardWidgetComponent } from '../app-card-widget/app-card-widget.component';
import { AppAndIconService } from '../services/app-and-icon.service';
import { DashboardDto, DashboardDtoWidgetsInner } from '../../generated/dashboard-api/v1';
import { IntroductionGuideService } from '../../introduction-guide/introduction-guide.service';

@Component({
  selector: 'gea-id-workspace-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  applicationId = 'publicDashboard';
  loginDisplay = true;
  baseWidgetSettings: WidgetSetting[] = [
    {
      h: 1,
      w: 1,
      type: 'Default',
      x: 0,
      y: 0,
    },
  ];
  storedWidgetSettings: WidgetSetting[] = [];
  savedDashboard: DashboardDto | undefined;
  widgetDefinitions: WidgetDefinitions = {
    Default: {
      component: DefaultWidgetComponent,
      h: 1,
      w: 1,
      widgetCallback: this.editAndAddWidget.bind(this),
    },
    TEST: { component: AppCardWidgetComponent, h: 1, w: 1 },
  };
  apps: App[] = [];
  console = console;
  editModeActive = false;

  constructor(
    private complexDialog: ComplexDialogV2Service,
    private dashboardService: DashboardService,
    private appService: AppAndIconService,
    private gaService: GoogleAnalyticsService,
    private introductionGuideService: IntroductionGuideService
  ) {}

  ngOnInit() {
    this.introductionGuideService.openByFirstLogin();
    this.appService
      .getAppsAndInitIcons()
      .pipe(take(1))
      .subscribe(({ appList, verifyAppList }) => {
        //Add app tile widgets to widgetDefinitions
        appList.forEach((app) => {
          app.widgetTypes.forEach((widgetType) => {
            this.widgetDefinitions[widgetType.name] = {
              component: AppCardWidgetComponent,
              h: 1,
              w: 1,
            };
          });
        });
        verifyAppList.forEach((app) => {
          app.widgetTypes.forEach((widgetType) => {
            this.widgetDefinitions[widgetType.name] = {
              component: AppCardWidgetComponent,
              h: 1,
              w: 1,
            };
          });
        });
        this.apps = appList;

        this.dashboardService
          .getDashboardInfo(this.applicationId)
          .pipe(take(1))
          .subscribe((dashboardInfo) => {
            if (dashboardInfo && dashboardInfo.length) {
              this.dashboardService
                .getDashboard(this.applicationId, dashboardInfo[0].id)
                .pipe(take(1))
                .subscribe((dashboard) => {
                  if (dashboard) {
                    this.savedDashboard = dashboard;
                    dashboard.widgets
                      .filter((widget) => !Object.keys(this.widgetDefinitions).includes(widget.type))
                      .forEach((widget: DashboardDtoWidgetsInner) => {
                        widget.disabled = true;
                        this.widgetDefinitions[widget.type] = {
                          component: AppCardWidgetComponent,
                          h: 1,
                          w: 1,
                        };
                      });
                    this.storedWidgetSettings = this.mapWidgetsToWidgetSettings(dashboard.widgets);
                  }
                });
            } else {
              this.storedWidgetSettings = this.baseWidgetSettings;
            }
          });
        if (this.gaService.loggedInUser) {
          this.gaService.metaPush('Dashboard');
        } else {
          // eslint-disable-next-line no-console
          console.warn('was not able to send ga event');
        }
      });
  }

  mapWidgetsToWidgetSettings(widgets: DashboardDtoWidgetsInner[]) {
    let mappedWidgets: WidgetSetting[] = widgets.map((widget) => ({
      h: widget.dimension?.height,
      w: widget.dimension?.width,
      type: widget.type,
      x: widget.position?.x,
      y: widget.position?.y,
      disabled: widget.disabled,
    }));
    // Use Default Widget if no widgets are saved on dashboard
    if (widgets.length < 1) {
      mappedWidgets = this.baseWidgetSettings;
    }
    return mappedWidgets;
  }

  openAddWidget() {
    this.complexDialog.open(
      {
        title: 'DASHBOARD.CHOOSE-APP',
        yes: 'X.BUTTON.SAVE',
        closable: true,
        hideButtons: false,
        showRejectButton: false,
        initialAcceptDisabled: false,
      },
      AddWidgetComponent
    );
  }

  editAndAddWidget() {
    this.editModeActive = true;
    this.openAddWidget();
  }

  onSaveGrid(widgetSettings: WidgetSetting[]) {
    const widgets = widgetSettings
      .map(
        (setting) =>
          ({
            id: setting.id && setting.id.startsWith(setting.type) ? undefined : setting.id,
            type: setting.type,
            dimension: {
              height: setting.h,
              width: setting.w,
            },
            position: {
              x: setting.x,
              y: setting.y,
            },
            disabled: setting.disabled,
          }) as DashboardDtoWidgetsInner
      )
      // filter out the DefaultWidget
      .filter((widget) => {
        return widget.type !== 'Default';
      });
    if (this.savedDashboard) {
      this.savedDashboard.widgets = widgets;
      this.dashboardService
        .updateDashboard(this.applicationId, this.savedDashboard)
        .pipe(take(1))
        .subscribe((dashboard) => {
          dashboard.widgets
            .filter((widget) => widgets.filter((w) => w.disabled).some((w) => w.type === widget.type && w.disabled))
            .forEach((widget) => (widget.disabled = true));
          this.storedWidgetSettings = this.mapWidgetsToWidgetSettings(dashboard.widgets);
        });
    } else {
      this.dashboardService
        .createDashboard(this.applicationId, { name: 'default', widgets: widgets })
        .pipe(take(1))
        .subscribe((dashboard) => {
          this.storedWidgetSettings = this.mapWidgetsToWidgetSettings(dashboard.widgets);
        });
    }
  }
}
